import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {CommunicationService} from './communication.service';
import {FileToUpload, UploadStatus} from '../models/files';

@Injectable({
  providedIn: 'root'
})
export class ReceiptUploadsService {

  public filesToUpload: FileToUpload[] = [];

  constructor(private api: CommunicationService) {
  }

  uploadFile(data: any): Observable<any> {
    data.UPLOAD_FILE.status = UploadStatus.inProcess;
    this.filesToUpload.push(data.UPLOAD_FILE);
    return this.api.uploadReceipt(data).pipe(
      map((response) => {
        data.UPLOAD_FILE.status = UploadStatus.success;
        return of(response);
      }),
      catchError((error) => {
        data.UPLOAD_FILE.status = UploadStatus.failed;
        throw new Error(error);
      })
    );
  }
}


