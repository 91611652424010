import * as tslib_1 from "tslib";
import { RcDeviceService } from '@realcommerce/rc-packages';
import * as i0 from "@angular/core";
var DeviceService = /** @class */ (function (_super) {
    tslib_1.__extends(DeviceService, _super);
    function DeviceService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Check if current device is mobile
     * @return boolean
     */
    DeviceService.prototype.isMobile = function () {
        return this.windowWidth < this.lg;
    };
    DeviceService.prototype.isSafari = function () {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('crios') > -1) {
                return false;
            }
            else {
                return true;
            }
        }
        return false;
    };
    DeviceService.ngInjectableDef = i0.defineInjectable({ factory: function DeviceService_Factory() { return new DeviceService(); }, token: DeviceService, providedIn: "root" });
    return DeviceService;
}(RcDeviceService));
export { DeviceService };
