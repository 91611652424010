import { CONSTANTS } from './constants';
var ɵ0 = {
    preload: true
}, ɵ1 = {
    preload: true
}, ɵ2 = {
    preload: true
}, ɵ3 = {
    preload: true
};
var routes = [
    {
        path: CONSTANTS.ROUTES.HOME.BASE,
        pathMatch: 'full',
        loadChildren: './feature/home/home.module#HomeModule',
        data: ɵ0
    },
    {
        path: '_callback',
        pathMatch: 'full',
        loadChildren: './feature/home/home.module#HomeModule',
        data: ɵ1
    },
    {
        path: CONSTANTS.ROUTES.RECEIPT_DETAILS.BASE,
        pathMatch: 'full',
        loadChildren: './feature/receipt-details/receipt-details.module#ReceiptDetailsModule',
        data: ɵ2
    },
    {
        path: CONSTANTS.ROUTES.RECEIPT_RESULT.BASE,
        pathMatch: 'full',
        loadChildren: './feature/receipt-result/receipt-result.module#ReceiptResultModule',
        data: ɵ3
    },
    {
        path: CONSTANTS.ROUTES.GUIDE.BASE,
        loadChildren: './feature/guide/guide.module#GuideModule'
    },
    {
        path: CONSTANTS.ROUTES.NOTIFICATIONS.BASE,
        loadChildren: './feature/notifications/notifications.module#NotificationsModule'
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
