export enum UploadStatus{
  preUpload = 0,
  inProcess = 1,
  success = 2,
  failed = 3,
}
export interface FileToUpload {
  fileToUpload: File;
  fileToUploadEncoded?: string;
  status?: UploadStatus;
}

export interface EmailFile {
  Key: string;
  LastModified: string;
  ETag: string;
  Size: number;
  StorageClass: string;
  AttachmentName: string;
  AttachmentExt: string;
  FILE: any;
}
