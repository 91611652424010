<div *ngIf="showProgressiveWebAppNotification" class="ProgressiveWebApp-notification" lib-rc-click-outside (clickOutside)="closeProgressiveWebAppNotification()">
  <div class="box">
    <button class=" btn close-icon" (click)="closeProgressiveWebAppNotification()"><img [src]="'/assets/icons/close-purple.svg' | rcImage"/></button>
    <div class="d-flex">
      <div class="add-icon">
        <img [src]="'/assets/icons/add.svg' | rcImage"/>
      </div>
      <div class="content">
        <div><span>Install this webapp on your iPhone:</span></div>
        <div>
          <span>tap</span>
          <span><img class="export-icon" [src]="'/assets/icons/send-activity.svg' | rcImage" /></span>
          <span>and then add to home screen</span>
        </div>


      </div>

    </div>
  </div>
</div>
