import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RcUiModule} from '@realcommerce/rc-packages';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {ComingSoonComponent} from './components/coming-soon/coming-soon.component';
import {LoaderComponent} from './components/loader/loader.component';
import {WarningPopupComponent} from './components/warning-popup/warning-popup.component';
import {GeneralErrorComponent} from './components/general-error/general-error.component';
import {PwaIOSNotificationComponent} from './components/pwa-ios-notification/pwa-ios-notification.component';

const sharedDeclarations = [
  ComingSoonComponent,
  LoaderComponent,
  WarningPopupComponent,
  GeneralErrorComponent,
  PwaIOSNotificationComponent

];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RcUiModule,
    RouterModule
  ],
  declarations: [
    sharedDeclarations,
  ],
  exports: [sharedDeclarations, RcUiModule]
})
export class ShareModule {
}
