import { CookieService } from 'ngx-cookie';
import { LanguageKey } from '../models';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie";
var LanguageService = /** @class */ (function () {
    function LanguageService(cookieService) {
        this.cookieService = cookieService;
        this.cookieKey = 'language';
        this.defaultLanguage = LanguageKey.english;
    }
    /**
     * get current language
     * @return string
     */
    LanguageService.prototype.getLanguage = function () {
        return this.cookieService.get(this.cookieKey) || this.defaultLanguage;
    };
    /**
     * Set language
     * @param lang
     */
    LanguageService.prototype.setLanguage = function (lang) {
        this.cookieService.put(this.cookieKey, lang);
    };
    LanguageService.ngInjectableDef = i0.defineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.inject(i1.CookieService)); }, token: LanguageService, providedIn: "root" });
    return LanguageService;
}());
export { LanguageService };
