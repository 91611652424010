import {Component, Input, OnInit} from '@angular/core';
import {CONSTANTS} from '../../../constants';
import {Router} from '@angular/router';
import {finalize} from 'rxjs/operators';
import {AuthService} from '../../../core/auth.service';

@Component({
  selector: 'app-general-error',
  templateUrl: './general-error.component.html',
  styleUrls: ['./general-error.component.scss']
})
export class GeneralErrorComponent implements OnInit {

  @Input() message: string = 'appInitError.message';
  @Input() showHomeButton: boolean = false;
  @Input() showReloadButton: boolean = false;

  constructor( private router: Router, private authService: AuthService) { }

  ngOnInit() {
  }

  onGoHome() {
    this.router.navigate([CONSTANTS.ROUTES.HOME.BASE]);
  }
  reload() {
    console.log('reload');
    sessionStorage.clear();
    // this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.authService.logout()
      .pipe(
        finalize(() => {
          // this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
        })
      ).subscribe();
  }

}
