import { environment } from '../environments/environment';
import { catchError, mergeMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { AmdocsAppInitService } from './amdocs-app-init/amdocs-app-init.service';
var imagesConfig = {
    baseUrl: ''
};
export function initializeApp(appInitService) {
    return function () {
        return appInitService.loadTranslation().pipe(mergeMap(function () {
            return appInitService.initApp().pipe(mergeMap(function (appInitResponse) {
                if (appInitResponse.success) {
                    return of(true); // TODO :: init app
                }
                else {
                    if (!appInitService.appInitError) {
                        appInitService.appInitError = appInitResponse.error;
                    }
                    console.log('APP INIT ERROR: ' + appInitResponse.error);
                }
                return of(appInitResponse);
            }), catchError(function (error) {
                appInitService.appInitError = error;
                appInitService.appFinishedLoaded = true;
                document.body.className = document.body.className.replace('show-splash', '');
                return throwError(error);
            }));
        })).toPromise();
    };
}
var loginGatewayConfig = {
    url: environment.loginGWBaseURL,
    region: 'eu-west-1',
    appid: 'myreceipts',
    refreshTokenInterval: environment.refreshTokenInterval
};
var ɵ0 = loginGatewayConfig, ɵ1 = imagesConfig;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
