import {Component, OnInit} from '@angular/core';
import {RcEventBusService} from '@realcommerce/rc-packages';
import {CONSTANTS} from './constants';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  public appLoaded = false;
  public appError = false;
  public addBlur = false;
  public displayNotifications = false;
  public displayMenu = true;
  public displayWarningPopup = false;
  public warningPopupTitle: string;
  public warningPopupText: string;
  public showSessionExpiredPopup: boolean;
  public showGenericErrorPopup: boolean;
  public showInvalidFilePopup: boolean;
  public showReloadButton: boolean;
  public isMinLoadTimePassed = false;


  constructor(private router: Router,
              private eventBus: RcEventBusService
  ) {
    this.addEvents();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.isMinLoadTimePassed = true;
    }, CONSTANTS.SPLASH_MIN_LOAD_TIME * 1000);
    this.appLoaded = true;
    this.appError = false;
    // this.initRoutesEvents();
    if (!localStorage.getItem('onBoardingDisplayed')) {
      localStorage.setItem('onBoardingDisplayed', '1');
      this.router.navigate([CONSTANTS.ROUTES.GUIDE.BASE, CONSTANTS.ROUTES.GUIDE.CHILDREN.ON_BOARDING]);
    }
  }

  addEvents() {
    this.eventBus.on(CONSTANTS.EVENTS.TOGGLE_BLUR, (data) => {
      this.addBlur = data;
    }, true);

    this.eventBus.on(CONSTANTS.EVENTS.OPEN_WARNING_POPUP, (data) => {
      this.displayWarningPopup = true;
      this.addBlur = true;
      this.warningPopupTitle = data.title;
      this.warningPopupText = data.text;
    }, true);

    this.eventBus.on(CONSTANTS.EVENTS.SESSION_EXPIRED, () => {
      this.showSessionExpiredPopup = true;
      this.addBlur = true;
    }, true);
    this.eventBus.on(CONSTANTS.EVENTS.GENERIC_POPUP, () => {
      this.showGenericErrorPopup = true;
      this.addBlur = true;
    }, true);

    this.eventBus.on(CONSTANTS.EVENTS.INVALID_FILE_POPUP, () => {
      this.showInvalidFilePopup = true;
      this.addBlur = true;
    }, true);
  }

  submitWarningPopup(approved = false) {
    this.eventBus.emit(CONSTANTS.EVENTS.WARNING_POPUP_CLOSED, approved);
    this.displayWarningPopup = false;
    this.onClosePopup();
  }

  onClosePopup() {
    this.addBlur = false;
  }
}

if (typeof (<any>window).silentRefreshAction === 'undefined') {
  (<any>window).silentRefreshAction = (EventName) => {
    const event = new CustomEvent(EventName);
    window.dispatchEvent(event);
  };
}

document.onvisibilitychange = () => {
  const event = new CustomEvent('rcVisibilityChanged');
  window.dispatchEvent(event);
};
