/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pwa-ios-notification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@realcommerce/rc-packages";
import * as i3 from "@angular/common";
import * as i4 from "./pwa-ios-notification.component";
import * as i5 from "../../../core/device.service";
var styles_PwaIOSNotificationComponent = [i0.styles];
var RenderType_PwaIOSNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PwaIOSNotificationComponent, data: {} });
export { RenderType_PwaIOSNotificationComponent as RenderType_PwaIOSNotificationComponent };
function View_PwaIOSNotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "ProgressiveWebApp-notification"], ["lib-rc-click-outside", ""]], null, [[null, "clickOutside"], ["document", "touchend"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:touchend" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onClick($event.target) !== false);
        ad = (pd_1 && ad);
    } if (("clickOutside" === en)) {
        var pd_2 = (_co.closeProgressiveWebAppNotification() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ɵq, [i1.ElementRef], null, { clickOutside: "clickOutside" }), (_l()(), i1.ɵeld(2, 0, null, null, 19, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", " btn close-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeProgressiveWebAppNotification() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "img", [], [[8, "src", 4]], null, null, null, null)), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 15, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "add-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "img", [], [[8, "src", 4]], null, null, null, null)), i1.ɵppd(9, 1), (_l()(), i1.ɵeld(10, 0, null, null, 11, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Install this webapp on your iPhone:"])), (_l()(), i1.ɵeld(14, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["tap"])), (_l()(), i1.ɵeld(17, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "img", [["class", "export-icon"]], [[8, "src", 4]], null, null, null, null)), i1.ɵppd(19, 1), (_l()(), i1.ɵeld(20, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["and then add to home screen"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), "/assets/icons/close-purple.svg")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent, 0), "/assets/icons/add.svg")); _ck(_v, 8, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 18, 0, _ck(_v, 19, 0, i1.ɵnov(_v.parent, 0), "/assets/icons/send-activity.svg")); _ck(_v, 18, 0, currVal_2); }); }
export function View_PwaIOSNotificationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.ɵbb, [[2, "ImageConfig"]]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PwaIOSNotificationComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showProgressiveWebAppNotification; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PwaIOSNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pwa-ios-notification", [], null, null, null, View_PwaIOSNotificationComponent_0, RenderType_PwaIOSNotificationComponent)), i1.ɵdid(1, 114688, null, 0, i4.PwaIOSNotificationComponent, [i5.DeviceService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PwaIOSNotificationComponentNgFactory = i1.ɵccf("app-pwa-ios-notification", i4.PwaIOSNotificationComponent, View_PwaIOSNotificationComponent_Host_0, {}, {}, []);
export { PwaIOSNotificationComponentNgFactory as PwaIOSNotificationComponentNgFactory };
