/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./feature/app-navbar/app-navbar.component.ngfactory";
import * as i3 from "./feature/app-navbar/app-navbar.component";
import * as i4 from "@realcommerce/rc-packages";
import * as i5 from "./core/auth.service";
import * as i6 from "../../node_modules/@realcommerce/rc-packages/realcommerce-rc-packages.ngfactory";
import * as i7 from "./share/components/warning-popup/warning-popup.component.ngfactory";
import * as i8 from "./share/components/warning-popup/warning-popup.component";
import * as i9 from "@angular/common";
import * as i10 from "./feature/alert-messages/alert-messages.component.ngfactory";
import * as i11 from "./feature/alert-messages/alert-messages.component";
import * as i12 from "@angular/router";
import * as i13 from "./share/components/general-error/general-error.component.ngfactory";
import * as i14 from "./share/components/general-error/general-error.component";
import * as i15 from "./share/components/pwa-ios-notification/pwa-ios-notification.component.ngfactory";
import * as i16 from "./share/components/pwa-ios-notification/pwa-ios-notification.component";
import * as i17 from "./core/device.service";
import * as i18 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "full-height show-splash"]], null, null, null, null, null))], null, null); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, i2.View_AppNavbarComponent_0, i2.RenderType_AppNavbarComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppNavbarComponent, [i4.RcEventBusService, i5.AuthService, i4.RcTranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AppComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "lib-rc-popup-wrapper", [], null, [[null, "closeCallback"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeCallback" === en)) {
        var pd_0 = (_co.submitWarningPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ɵd_0, i6.RenderType_ɵd)), i1.ɵdid(1, 4374528, null, 0, i4.ɵd, [], { customClass: [0, "customClass"] }, { closeCallback: "closeCallback" }), (_l()(), i1.ɵeld(2, 0, null, 2, 1, "app-warning-popup", [], null, [[null, "submit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (_co.submitWarningPopup(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_WarningPopupComponent_0, i7.RenderType_WarningPopupComponent)), i1.ɵdid(3, 114688, null, 0, i8.WarningPopupComponent, [i4.RcEventBusService], { title: [0, "title"], text: [1, "text"], showApproveBtn: [2, "showApproveBtn"] }, { submit: "submit" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "warning-popup"; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.warningPopupTitle; var currVal_2 = _co.warningPopupText; var currVal_3 = true; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, null); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_4)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-alert-messages", [], null, null, null, i10.View_AlertMessagesComponent_0, i10.RenderType_AlertMessagesComponent)), i1.ɵdid(4, 114688, null, 0, i11.AlertMessagesComponent, [i4.RcEventBusService, i4.RcTranslateService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_5)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "main-content-wrapper full-height"]], null, null, null, null, null)), i1.ɵdid(8, 278528, null, 0, i9.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "blur": 0 }), (_l()(), i1.ɵeld(10, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(11, 212992, null, 0, i12.RouterOutlet, [i12.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayMenu; _ck(_v, 2, 0, currVal_0); _ck(_v, 4, 0); var currVal_1 = _co.displayWarningPopup; _ck(_v, 6, 0, currVal_1); var currVal_2 = "main-content-wrapper full-height"; var currVal_3 = _ck(_v, 9, 0, _co.addBlur); _ck(_v, 8, 0, currVal_2, currVal_3); _ck(_v, 11, 0); }, null); }
function View_AppComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-general-error", [], null, null, null, i13.View_GeneralErrorComponent_0, i13.RenderType_GeneralErrorComponent)), i1.ɵdid(2, 114688, null, 0, i14.GeneralErrorComponent, [i12.Router, i5.AuthService], { showReloadButton: [0, "showReloadButton"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showReloadButton; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AppComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "lib-rc-popup-wrapper", [], null, [[null, "closeCallback"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeCallback" === en)) {
        _co.showSessionExpiredPopup = false;
        var pd_0 = (_co.onClosePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ɵd_0, i6.RenderType_ɵd)), i1.ɵdid(1, 4374528, null, 0, i4.ɵd, [], { customClass: [0, "customClass"], canClose: [1, "canClose"] }, { closeCallback: "closeCallback" }), (_l()(), i1.ɵeld(2, 0, null, 2, 2, "app-warning-popup", [], null, null, null, i7.View_WarningPopupComponent_0, i7.RenderType_WarningPopupComponent)), i1.ɵdid(3, 114688, null, 0, i8.WarningPopupComponent, [i4.RcEventBusService], { text: [0, "text"], showReloadBtn: [1, "showReloadBtn"] }, null), i1.ɵppd(4, 1)], function (_ck, _v) { var currVal_0 = "warning-popup"; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), "sessionExpiredPopup.message")); var currVal_3 = true; _ck(_v, 3, 0, currVal_2, currVal_3); }, null); }
function View_AppComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "lib-rc-popup-wrapper", [], null, [[null, "closeCallback"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeCallback" === en)) {
        _co.showGenericErrorPopup = false;
        var pd_0 = (_co.onClosePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ɵd_0, i6.RenderType_ɵd)), i1.ɵdid(1, 4374528, null, 0, i4.ɵd, [], { customClass: [0, "customClass"], canClose: [1, "canClose"] }, { closeCallback: "closeCallback" }), (_l()(), i1.ɵeld(2, 0, null, 2, 2, "app-warning-popup", [], null, null, null, i7.View_WarningPopupComponent_0, i7.RenderType_WarningPopupComponent)), i1.ɵdid(3, 114688, null, 0, i8.WarningPopupComponent, [i4.RcEventBusService], { text: [0, "text"], showReloadBtn: [1, "showReloadBtn"] }, null), i1.ɵppd(4, 1)], function (_ck, _v) { var currVal_0 = "warning-popup"; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), "genericErrorPopup.message")); var currVal_3 = true; _ck(_v, 3, 0, currVal_2, currVal_3); }, null); }
function View_AppComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "lib-rc-popup-wrapper", [], null, [[null, "closeCallback"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeCallback" === en)) {
        _co.showInvalidFilePopup = false;
        var pd_0 = (_co.onClosePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ɵd_0, i6.RenderType_ɵd)), i1.ɵdid(1, 4374528, null, 0, i4.ɵd, [], { customClass: [0, "customClass"], canClose: [1, "canClose"] }, { closeCallback: "closeCallback" }), (_l()(), i1.ɵeld(2, 0, null, 2, 2, "app-warning-popup", [], null, [[null, "submit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        _co.showInvalidFilePopup = false;
        var pd_0 = (_co.onClosePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_WarningPopupComponent_0, i7.RenderType_WarningPopupComponent)), i1.ɵdid(3, 114688, null, 0, i8.WarningPopupComponent, [i4.RcEventBusService], { text: [0, "text"], showApproveBtn: [1, "showApproveBtn"] }, { submit: "submit" }), i1.ɵppd(4, 1)], function (_ck, _v) { var currVal_0 = "warning-popup"; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), "invalidFilePopup.message")); var currVal_3 = true; _ck(_v, 3, 0, currVal_2, currVal_3); }, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_6)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_7)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_8)), i1.ɵdid(8, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_9)), i1.ɵdid(10, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-pwa-ios-notification", [], null, null, null, i15.View_PwaIOSNotificationComponent_0, i15.RenderType_PwaIOSNotificationComponent)), i1.ɵdid(12, 114688, null, 0, i16.PwaIOSNotificationComponent, [i17.DeviceService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.appError; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.appError; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.showSessionExpiredPopup; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.showGenericErrorPopup; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.showInvalidFilePopup; _ck(_v, 10, 0, currVal_4); _ck(_v, 12, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.ɵba, [i4.RcTranslateService]), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "full-height"], ["id", "main-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.appLoaded || !_co.isMinLoadTimePassed); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.appLoaded && _co.isMinLoadTimePassed); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i18.AppComponent, [i12.Router, i4.RcEventBusService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i18.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
