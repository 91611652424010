import {Injectable} from '@angular/core';
import {RcTimeService} from '@realcommerce/rc-packages';

@Injectable({
  providedIn: 'root'
})
export class TimeService extends RcTimeService {

  private maxValidDate: string;

  constructor() {
    super();
  }
}
