import { ApiService } from './api.service';
import { of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
// import {JwtHelperService} from '@auth0/angular-jwt';
import { AmdocsTimeService } from '../amdocs-time/amdocs-time.service';
import { AMDOCS_CONSTANTS } from '../amdocs-constants';
import { AmdocsAppInitResponse } from './app.service';
import { ApiError } from '../models/api.model';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "ngx-cookie";
import * as i3 from "@angular/router";
import * as i4 from "../amdocs-time/amdocs-time.service";
var AuthService = /** @class */ (function () {
    function AuthService(loginGatewayConfig, apiService, cookieService, router, timeService) {
        this.apiService = apiService;
        this.cookieService = cookieService;
        this.router = router;
        this.timeService = timeService;
        this.loginConfig = loginGatewayConfig;
    }
    // public makeSTSLogin(): Observable<any> {
    //   if (!this.cookieService.get('TOKEN')) {
    //     return throwError({});
    //   }
    //   return this.apiService.makeSTSLogin().pipe(map((response: any) => {
    //     try {
    //       const credentials = response.AssumeRoleWithWebIdentityResponse.AssumeRoleWithWebIdentityResult.Credentials;
    //       this.setGateWayCredentials(credentials);
    //       return response;
    //     } catch (e) {
    //       return throwError(response);
    //     }
    //   }));
    // }
    // public setGateWayCredentials(credentials: any) {
    //   this.cookieService.put('AccessKeyId', credentials.AccessKeyId);
    //   this.cookieService.put('Expiration', credentials.Expiration);
    //   this.cookieService.put('SecretAccessKey', credentials.SecretAccessKey);
    //   this.cookieService.put('SessionToken', credentials.SessionToken);
    // }
    AuthService.prototype.clearGateWayCredentials = function () {
        this.cookieService.remove('AccessKeyId');
        this.cookieService.remove('Expiration');
        this.cookieService.remove('SecretAccessKey');
        this.cookieService.remove('SessionToken');
        this.cookieService.remove('TOKEN');
    };
    // public isADFSExpired() {
    //   const token = this.cookieService.get('TOKEN');
    //   return !token || this.jwtHelper.isTokenExpired(token);
    // }
    AuthService.prototype.isPkceValid = function () {
        var now = (this.timeService.moment().unix() * 1000);
        var exp = this.cookieService.get(AMDOCS_CONSTANTS.PKCE_EXPIRATION);
        if (exp) {
            return parseInt(exp, 10) > now;
        }
        return false;
    };
    AuthService.prototype.removePkceExp = function () {
        this.cookieService.remove(AMDOCS_CONSTANTS.PKCE_EXPIRATION);
    };
    AuthService.prototype.login = function (code) {
        var _this = this;
        return this.apiService.login(code).pipe(mergeMap(function (response) {
            _this.setLoginCredentials(response);
            return of(response);
        }));
    };
    AuthService.prototype.setLoginCredentials = function (loginCredentials) {
        var options = {
            path: '/',
            secure: true
        };
        var stsCreds = loginCredentials.stsCredentials;
        this.cookieService.put(AMDOCS_CONSTANTS.ACCESS_KEY_ID, stsCreds.accessKeyId, options);
        this.cookieService.put(AMDOCS_CONSTANTS.SECRET_ACCESS_KEY, stsCreds.secretAccessKey, options);
        this.cookieService.put(AMDOCS_CONSTANTS.SESSION_TOKEN, stsCreds.sessionToken, options);
        this.cookieService.put(AMDOCS_CONSTANTS.EXPIRATION, stsCreds.expiration, options);
        this.cookieService.put(AMDOCS_CONSTANTS.TOKEN_KEY, loginCredentials.idToken, options);
        this.cookieService.put(AMDOCS_CONSTANTS.TOKEN_EXPIRATION_KEY, loginCredentials.idTokenExpiration.toString(), options);
        this.cookieService.put(AMDOCS_CONSTANTS.CAS_TOKEN_EXPIRATION_KEY, loginCredentials.casTokenExpiration.toString(), options);
    };
    /**
     * return if user is logged in
     */
    AuthService.prototype.isLoggedIn = function () {
        var token = this.getToken();
        if (token) {
            var exp = this.cookieService.get(AMDOCS_CONSTANTS.TOKEN_EXPIRATION_KEY);
            if (exp) {
                return this.isExpired(parseInt(exp, 10)) ? false : true;
            }
            else {
                return true;
            }
        }
        return false;
    };
    AuthService.prototype.getToken = function () {
        return this.cookieService.get(AMDOCS_CONSTANTS.TOKEN_KEY);
    };
    AuthService.prototype.isExpired = function (exp) {
        var now = (this.timeService.moment().unix() * 1000);
        return now > exp;
    };
    AuthService.prototype.hasRefreshTokenCookies = function () {
        var now = (this.timeService.moment().unix() * 1000);
        var exp = this.cookieService.get(AMDOCS_CONSTANTS.CAS_TOKEN_EXPIRATION_KEY);
        if (exp) {
            return parseInt(exp, 10) > now;
        }
        return false;
    };
    AuthService.prototype.callRefreshUserToken = function () {
        var _this = this;
        return this.apiService.refreshToken().pipe(mergeMap(function (response) {
            _this.setLoginCredentials(response);
            return of(response);
        }), catchError(function (error) {
            _this.initGetLoginUrlFlow().subscribe();
            return throwError(error);
        }));
    };
    AuthService.prototype.initGetLoginUrlFlow = function () {
        var _this = this;
        sessionStorage.removeItem('orig-path');
        return this.getLoginURL().pipe(mergeMap(function (response) {
            sessionStorage.setItem('orig-path', window.location.pathname + window.location.search);
            if (response.redirectUri) {
                _this.setpkceExp(response.pkceExpiration);
                window.location = response.redirectUri;
                // window.open(response.redirectUri);
                return of(new AmdocsAppInitResponse(true));
            }
            else {
                return throwError(new ApiError(0, 'could not get login url'));
            }
        }));
    };
    AuthService.prototype.getLoginURL = function () {
        return this.apiService.getLoginUrl().pipe(mergeMap(function (response) {
            return of(response);
        }));
    };
    AuthService.prototype.setpkceExp = function (pkceExp) {
        var options = {
            path: '/',
            secure: true
        };
        this.cookieService.put(AMDOCS_CONSTANTS.PKCE_EXPIRATION, "" + pkceExp, options);
    };
    AuthService.prototype.idTokenIsAboutToExpired = function () {
        var now = (this.timeService.moment().unix() * 1000);
        var exp = this.cookieService.get(AMDOCS_CONSTANTS.TOKEN_EXPIRATION_KEY);
        if (exp) {
            return parseInt(exp, 10) - now < 2400000;
        }
        return true;
    };
    AuthService.prototype.refreshUserToken = function () {
        var _this = this;
        this.clearRefreshTokenInterval();
        this.callRefreshUserToken().pipe(mergeMap(function (response) {
            _this.setRefreshTokenInterval();
            return of(response);
        })).subscribe();
    };
    AuthService.prototype.clearRefreshTokenInterval = function () {
        if (this.refreshTokenThread) {
            clearInterval(this.refreshTokenThread);
        }
    };
    AuthService.prototype.setRefreshTokenInterval = function () {
        var _this = this;
        this.clearRefreshTokenInterval();
        this.refreshTokenThread = setInterval(function () {
            _this.refreshUserToken();
        }, this.loginConfig.refreshTokenInterval);
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        return this.apiService.getLogoutUrl().pipe(mergeMap(function (response) {
            _this.cookieService.removeAll();
            window.location = response.redirectUri;
            return of(response);
        }));
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject("ILoginGatewayConfig"), i0.inject(i1.ApiService), i0.inject(i2.CookieService), i0.inject(i3.Router), i0.inject(i4.AmdocsTimeService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
