import * as tslib_1 from "tslib";
import { RcFunctionsService, RcTranslateService } from '@realcommerce/rc-packages';
import { TimeService } from './time.service';
import * as i0 from "@angular/core";
import * as i1 from "./time.service";
import * as i2 from "@realcommerce/rc-packages";
var FunctionsService = /** @class */ (function (_super) {
    tslib_1.__extends(FunctionsService, _super);
    function FunctionsService(timeService, translateService) {
        var _this = _super.call(this) || this;
        _this.timeService = timeService;
        _this.translateService = translateService;
        return _this;
    }
    FunctionsService.ngInjectableDef = i0.defineInjectable({ factory: function FunctionsService_Factory() { return new FunctionsService(i0.inject(i1.TimeService), i0.inject(i2.RcTranslateService)); }, token: FunctionsService, providedIn: "root" });
    return FunctionsService;
}(RcFunctionsService));
export { FunctionsService };
