/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./general-error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@realcommerce/rc-packages";
import * as i3 from "@angular/common";
import * as i4 from "./general-error.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../core/auth.service";
var styles_GeneralErrorComponent = [i0.styles];
var RenderType_GeneralErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GeneralErrorComponent, data: {} });
export { RenderType_GeneralErrorComponent as RenderType_GeneralErrorComponent };
function View_GeneralErrorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onGoHome() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "comingSoon.linkToHome")); _ck(_v, 1, 0, currVal_0); }); }
function View_GeneralErrorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "genericErrorPopup.reload")); _ck(_v, 1, 0, currVal_0); }); }
export function View_GeneralErrorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.ɵba, [i2.RcTranslateService]), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "general-error-main-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "general-error-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "general-error-message-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "main-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "main-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵppd(9, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GeneralErrorComponent_1)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GeneralErrorComponent_2)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.showHomeButton; _ck(_v, 11, 0, currVal_2); var currVal_3 = _co.showHomeButton; _ck(_v, 13, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), "appInitError.oops")); _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), _co.message)); _ck(_v, 8, 0, currVal_1); }); }
export function View_GeneralErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-general-error", [], null, null, null, View_GeneralErrorComponent_0, RenderType_GeneralErrorComponent)), i1.ɵdid(1, 114688, null, 0, i4.GeneralErrorComponent, [i5.Router, i6.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GeneralErrorComponentNgFactory = i1.ɵccf("app-general-error", i4.GeneralErrorComponent, View_GeneralErrorComponent_Host_0, { message: "message", showHomeButton: "showHomeButton", showReloadButton: "showReloadButton" }, {}, []);
export { GeneralErrorComponentNgFactory as GeneralErrorComponentNgFactory };
