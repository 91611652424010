import {environment} from '../environments/environment';

export const CONSTANTS = {
  ROUTES: {
    HOME: {
      BASE: ''
    },
    RECEIPT_DETAILS: {
      BASE: 'receipt-details'
    },
    RECEIPT_RESULT: {
      BASE: 'receipt-result'
    },
    GUIDE: {
      BASE: 'guide',
      CHILDREN: {
        MANUAL: '',
        ON_BOARDING: 'onBoarding'
      }
    },
    NOTIFICATIONS: {
      BASE: 'notifications'
    },
    REPORT_ISSUE:{
      BASE:'https://amdocsprodmain.service-now.com/ta?id=ta_sc_cat_item&sys_id=991d8b16372d03400b75005a54990ee9&category_sys_id=f18e9f5cdbd12b443780e1aa4b961901'
    }
  },
  LANGUAGE: {
    EN: 'en'
  },
  EVENTS: {
    ALERT_MESSAGE: 'alert-message',
    TOGGLE_BLUR: 'toggle-blur',
    OPEN_WARNING_POPUP: 'open-warning-popup',
    WARNING_POPUP_CLOSED: 'warning-popup-closed',
    CHECK_FOR_TOKEN_EXPIRATION: 'check-for-token-expiration',
    SESSION_EXPIRED: 'session-expired',
    GENERIC_POPUP: 'generic-popup',
    INVALID_FILE_POPUP: 'invalid-file-popup',
    DELETE_POPUP: 'delete_popup',
    DELETE_POPUP_DISABLED: 'delete_popup_disabled',
    RENEW_STS: 'renew-sts',
    CUSTOM_FOOTER_CLASS: 'custom-footer-class',
    REDIRECT_HOME:'redirect-home'
  },
  DEST_COUNTRY: 'DestCountry',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  DISABLE_BODY_SCROLL_CLASS: 'disable-body-scroll',
  HOUR_PLACEHOLDER: '---',
  LOGOUT_URL: 'https://fs.amdocs.com/adfs/oauth2/logout',
  REFRESH_TOKEN_IFRAME_URL: '',
  PWA_NOTIFICATION_COUNTER_KEY_LOCAL_STORAGE: 'pwa-notification',
  PWA_NOTIFICATION_COUNTER_MAX_DISPLAYS: 3,
  REFRESH_TOKEN_IFRAME_TIMEOUT: 30000,
  REFRESH_TOKEN_IFRAME_INTERVAL: environment.refreshTokenInterval,
  SMALL_MOBILE_DISPLAY: 360,
  SPLASH_MIN_LOAD_TIME: 3
};
