import {Component, Input, OnInit} from '@angular/core';
import {RcEventBusService, RcTranslateService} from '@realcommerce/rc-packages';
import {CONSTANTS} from '../../constants';

@Component({
  selector: 'app-alert-messages',
  templateUrl: './alert-messages.component.html',
  styleUrls: ['./alert-messages.component.scss']
})
export class AlertMessagesComponent implements OnInit {

  public defaultMessage = [{message: this.translateService.getText('errors.defaultToastrError'), type: ''}];
  public alertMessage = this.defaultMessage;
  public showAlert = false;

  @Input() timeToShow = 0;

  constructor(private eventBus: RcEventBusService, private translateService: RcTranslateService) { }

  ngOnInit() {
    this.eventBus.on(CONSTANTS.EVENTS.ALERT_MESSAGE, (data) => {
      this.alertMessage = data || this.defaultMessage;
      this.showAlertMessage();
    }, true);
  }

  showAlertMessage() {
    this.showAlert = true;

    if (this.timeToShow) {
      setTimeout(() => {
        this.showAlert = false;
      }, this.timeToShow);
    }

  }
  onTryAgain() {
    console.log('onTryAgain');
  }

  onClose() {
    this.showAlert = false;
  }

}
