<div id="alert-messages">
  <div class="alert-messages-wrapper" [ngClass]="{'active': showAlert, 'align-item-center': alertMessage.length === 1}">
    <div>
      <div class="alert-messages-content-wrapper" [ngClass]="{'multi-lines': alertMessage.length > 1}" *ngFor="let msg of alertMessage">
        <span class="alert-message-icon icon-info"></span>
        <span class="alert-message-text" [innerHTML]="msg.message"></span>
        <!--<span class="alert-message-link" (click)="onTryAgain()">Try again</span>-->
      </div>
    </div>

    <span class="alert-message-close" (click)="onClose()"> {{"general.x" | rcTranslate}} </span>
  </div>
</div>
