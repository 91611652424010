/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert-messages.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@realcommerce/rc-packages";
import * as i4 from "./alert-messages.component";
var styles_AlertMessagesComponent = [i0.styles];
var RenderType_AlertMessagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertMessagesComponent, data: {} });
export { RenderType_AlertMessagesComponent as RenderType_AlertMessagesComponent };
function View_AlertMessagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "alert-messages-content-wrapper"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "multi-lines": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "alert-message-icon icon-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "alert-message-text"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "alert-messages-content-wrapper"; var currVal_1 = _ck(_v, 2, 0, (_co.alertMessage.length > 1)); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.message; _ck(_v, 4, 0, currVal_2); }); }
export function View_AlertMessagesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.ɵba, [i3.RcTranslateService]), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["id", "alert-messages"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "alert-messages-wrapper"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "active": 0, "align-item-center": 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertMessagesComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "alert-message-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵppd(10, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "alert-messages-wrapper"; var currVal_1 = _ck(_v, 4, 0, _co.showAlert, (_co.alertMessage.length === 1)); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.alertMessage; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), "general.x")); _ck(_v, 9, 0, currVal_3); }); }
export function View_AlertMessagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert-messages", [], null, null, null, View_AlertMessagesComponent_0, RenderType_AlertMessagesComponent)), i1.ɵdid(1, 114688, null, 0, i4.AlertMessagesComponent, [i3.RcEventBusService, i3.RcTranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertMessagesComponentNgFactory = i1.ɵccf("app-alert-messages", i4.AlertMessagesComponent, View_AlertMessagesComponent_Host_0, { timeToShow: "timeToShow" }, {}, []);
export { AlertMessagesComponentNgFactory as AlertMessagesComponentNgFactory };
