import {Injectable} from '@angular/core';
import * as moment_ from 'moment';
import {Moment} from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AmdocsTimeService {

  public defaultViewDateFormat: string;
  public defaultViewTimeFormat: string;
  public defaultViewDateTimeFormat: string;
  public defaultServerDateFormat: string;
  public defaultServerDateTimeFormat: string;
  public fullDateTimeFormat: string;
  protected momentRef = moment_;

  constructor() {
    this.defaultViewDateFormat = 'DD/MM/YYYY';
    this.defaultViewTimeFormat = 'HH:mm';
    this.defaultViewDateTimeFormat = `${this.defaultViewDateFormat} ${this.defaultViewTimeFormat}`;
    this.defaultServerDateFormat = 'YYYY-MM-DD';
    this.defaultServerDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
    this.fullDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
  }


  /**
   * get moment object
   * @param dateString - string of date e.g. '2011-02-15'
   * @param dateFormat - format of date e.g. 'YYYY-MM-DD'
   */
  moment(dateString: string | number = null, dateFormat: string = null): Moment {
    if (dateString === null) {
      return this.momentRef();
    } else {
      return this.momentRef(dateString, dateFormat);
    }
  }

  /**
   * get moment object from UTC datetime string
   * @param dateString - string of date e.g. '2011-02-15 12:00:00'
   */
  momentFromUTCToLocal(dateString: string): Moment {
    return this.momentRef.utc(dateString).local();
  }

  /**
   * Format a date
   * @param date
   * @param from
   * @param to
   * @returns string
   */
  format(date, from = this.defaultViewDateFormat, to = this.defaultServerDateFormat): string {
    if (this.momentRef(date, from).isValid()) {
      return this.momentRef(date, from).format(to);
    } else {
      return null;
    }
  }

}

