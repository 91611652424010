import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RcUtilsModule} from '@realcommerce/rc-packages';
import {ApiService} from './api.service';
import {AppService} from './app.service';
import {AuthService} from './auth.service';
import {CookieModule} from 'ngx-cookie';
import {LanguageService} from './language.service';
import {CommunicationService} from './communication.service';
import {FunctionsService} from './functions.service';
import {ReceiptUploadsService} from './receipt-uploads.service';

@NgModule({
  imports: [
    CommonModule,
    RcUtilsModule,
    CookieModule.forRoot()
  ],
  providers: [
    AppService,
    ApiService,
    CommunicationService,
    AuthService,
    LanguageService,
    FunctionsService,
    ReceiptUploadsService
  ]
})
export class CoreModule {
}
