import * as tslib_1 from "tslib";
import { RcTimeService } from '@realcommerce/rc-packages';
import * as i0 from "@angular/core";
var TimeService = /** @class */ (function (_super) {
    tslib_1.__extends(TimeService, _super);
    function TimeService() {
        return _super.call(this) || this;
    }
    TimeService.ngInjectableDef = i0.defineInjectable({ factory: function TimeService_Factory() { return new TimeService(); }, token: TimeService, providedIn: "root" });
    return TimeService;
}(RcTimeService));
export { TimeService };
