import { Component, OnInit } from '@angular/core';
import {CONSTANTS} from '../../../constants';
import {DeviceService} from '../../../core/device.service';

@Component({
  selector: 'app-pwa-ios-notification',
  templateUrl: './pwa-ios-notification.component.html',
  styleUrls: ['./pwa-ios-notification.component.scss']
})
export class PwaIOSNotificationComponent implements OnInit {

  public showProgressiveWebAppNotification: boolean;

  constructor(private deviceService: DeviceService) { }

  ngOnInit() {
    this.showProgressiveWebAppNotification = this.displayProgressiveWebAppNotification();

  }

  displayProgressiveWebAppNotification() {
    if (this.deviceService.isIOS() && this.deviceService.isSafari()) {
      const counter = parseInt(localStorage.getItem(CONSTANTS.PWA_NOTIFICATION_COUNTER_KEY_LOCAL_STORAGE), 10) || 0;
      if (counter < CONSTANTS.PWA_NOTIFICATION_COUNTER_MAX_DISPLAYS) {
        localStorage.setItem(CONSTANTS.PWA_NOTIFICATION_COUNTER_KEY_LOCAL_STORAGE, (counter + 1).toString());
        return true;
      }
    }
    return false;
  }

  closeProgressiveWebAppNotification() {
    this.showProgressiveWebAppNotification = false;
  }

}
