<div class="warning-message">
  <div class="inner-content">
    <h1>{{ title | rcTranslate }}</h1>
    <p [innerHTML]="text"></p>
  </div>
  <div class="footer">
    <button *ngIf="showApproveBtn" class="btn btn-primary popup-btn" (click)="onApprove()">{{'generalPopup.approve' | rcTranslate}}</button>
    <button *ngIf="showReloadBtn" class="btn btn-primary popup-btn" (click)="reload()"> {{'sessionExpiredPopup.reload' | rcTranslate}} </button>
  </div>
</div>
