// export class ApiResponse {
//   constructor(
//     public result: any,
//     public isOK: boolean,
//     public error: ApiError
//   ){}
// }
export class ApiError {
  constructor(
    public code: number,
    public message: string,
    public errorType?: string
  ){}
}

export interface IApiGatewayClient {
  call?: Function
}
