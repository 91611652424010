import {Injectable} from '@angular/core';
import {RcDeviceService} from '@realcommerce/rc-packages';



@Injectable({
  providedIn: 'root'
})
export class DeviceService extends RcDeviceService {

  /**
   * Check if current device is mobile
   * @return boolean
   */
  public isMobile(): boolean {
    return this.windowWidth < this.lg;
  }

  public isSafari(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('crios') > -1) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

}
