import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {RcEventBusService} from '@realcommerce/rc-packages';
import {AsyncSubject, BehaviorSubject, forkJoin, Observable, of, Subject} from 'rxjs';
import {FunctionsService} from './functions.service';
import {map, mergeMap} from "rxjs/operators";
import {isNullOrUndefined} from "util";

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  private modeAPI = 1;
  private modeSocket = 2;
  private communicationMode = this.modeAPI; // API
  public subjects: any = {};
  private travelsCache: any;

  // Storing Previous selected travel locaion
  selectedTravel: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedTravel$ = this.selectedTravel.asObservable();


  // private communicationMode = 2; // socket
  constructor(private restApi: ApiService, private eventBus: RcEventBusService, private functionsService: FunctionsService) {

  }

  public uploadReceipt(data): Observable<any> {
    if (this.communicationMode === this.modeAPI) {
      return this.restApi.uploadReceipt(data);
    } else {
      return this.makeSocketCall({});
    }
  }

  public getTravels(force = true): Observable<any> {
    if (!force && this.travelsCache) {
      return of(this.travelsCache);
    }
    this.travelsCache = null;
    if (this.communicationMode === this.modeAPI) {
      return this.restApi.getTravels().pipe(
        map((response: any) => {

          // ORDER BY
          if (response.Data.length) {
            response.Data.forEach((travel) => {
              if (isNullOrUndefined(travel.TRAVEL.EXPENSES)) {
                travel.TRAVEL.EXPENSES = [];
              }
              if (travel.TRAVEL.EXPENSES.length) {
                this.functionsService.sortArrayByKey(travel.TRAVEL.EXPENSES, 'ORDER', false);
              }
            });

          }
          this.travelsCache = response;
          return response;
        })
      );
    } else {
      return this.makeSocketCall({});
    }
  }

  public getAttachmentList(): Observable<any> {
    if (this.communicationMode === this.modeAPI) {
      return this.restApi.getAttachmentList().pipe(
        map((response: any) => {
          return response;
        }));

    } else {
      return this.makeSocketCall({});
    }
  }

  public getFileDetails(keysArray): Observable<any> {
    const calls = [];

    keysArray.forEach((key) => {
      calls.push(this.getAttachment(key));
    });



    return forkJoin(calls).pipe(
      mergeMap((response: any[]) => {
        const formattedRes = [];
        response.forEach((res) => {
          formattedRes.push(res.Data.Travel);
        });
        return of(formattedRes);
      })
    );
  }

  public getAttachment(key): Observable<any> {
    if (this.communicationMode === this.modeAPI) {
      return this.restApi.getAttachment(key).pipe(
        map((response: any) => {
          return response;
        }));

    } else {
      return this.makeSocketCall({});
    }
  }

  private makeSocketCall(params) {
    const transactionId = this.functionsService.createGuid();
    if (this.subjects[transactionId]) {
      this.subjects[transactionId] = new Subject();
    }
    // TODO :: send message via socket

    this.eventBus.on(transactionId, (payload: any) => {
      if (this.subjects[transactionId]) {
        if (payload.error) {
          this.subjects[transactionId].error(payload);
        } else {
          this.subjects[transactionId].next(payload);
        }
        this.subjects[transactionId].complete();

        this.eventBus.off(transactionId);
        this.subjects[transactionId].unsubscribe();
        delete this.subjects[transactionId];
      }
    });
    return this.subjects[transactionId];
  }

  // Set selected travel location 
  public setTravel(travel) {
    this.selectedTravel.next(travel);
   }
}
