/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-navbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@realcommerce/rc-packages";
import * as i5 from "./app-navbar.component";
import * as i6 from "../../core/auth.service";
var styles_AppNavbarComponent = [i0.styles];
var RenderType_AppNavbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppNavbarComponent, data: {} });
export { RenderType_AppNavbarComponent as RenderType_AppNavbarComponent };
function View_AppNavbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "a", [["class", "nav-icon icon-menu"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_AppNavbarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "img", [["class", "oval"]], [[8, "src", 4]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent, 0), "/assets/icons/oval-red.svg")); _ck(_v, 0, 0, currVal_0); }); }
function View_AppNavbarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "a", [["class", "nav-line-wrapper"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onToggle($event, _v.context.$implicit.displayName) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵpod(6, { exact: 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppNavbarComponent_4)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.path, ""); _ck(_v, 2, 0, currVal_2); var currVal_3 = _ck(_v, 6, 0, _v.context.$implicit.exact); var currVal_4 = "active"; _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_7 = _v.context.$implicit.showOval; _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵinlineInterpolate(1, "icon ", _v.context.$implicit.icon, ""); _ck(_v, 7, 0, currVal_5); var currVal_6 = _v.context.$implicit.displayName; _ck(_v, 9, 0, currVal_6); }); }
function View_AppNavbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "nav-content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "a", [["class", "close-icon icon-close-purple"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [["class", "nav-list-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppNavbarComponent_3)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "footer-area-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "nav-line-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "icon icon-logout"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵppd(11, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navItems; _ck(_v, 5, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent, 1), "navbar.logout")); _ck(_v, 10, 0, currVal_1); }); }
export function View_AppNavbarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.ɵbb, [[2, "ImageConfig"]]), i1.ɵpid(0, i4.ɵba, [i4.RcTranslateService]), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["id", "nav-main-wrapper"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavMainWrapperClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { "nav-open": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppNavbarComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "nav-wrapper"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavWrapperClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "open": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppNavbarComponent_2)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "powered-by"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [["src", "/assets/icons/powered-by.png"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.open); _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.open; _ck(_v, 6, 0, currVal_1); var currVal_2 = "nav-wrapper"; var currVal_3 = _ck(_v, 9, 0, _co.open); _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_4 = _co.open; _ck(_v, 11, 0, currVal_4); }, null); }
export function View_AppNavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, View_AppNavbarComponent_0, RenderType_AppNavbarComponent)), i1.ɵdid(1, 114688, null, 0, i5.AppNavbarComponent, [i4.RcEventBusService, i6.AuthService, i4.RcTranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppNavbarComponentNgFactory = i1.ɵccf("app-navbar", i5.AppNavbarComponent, View_AppNavbarComponent_Host_0, {}, {}, []);
export { AppNavbarComponentNgFactory as AppNavbarComponentNgFactory };
