<div class="general-error-main-wrapper">
  <div class="general-error-content">
<!--    <div class="general-error-icon-wrapper">-->
<!--        <img [src]="'/assets/icons/moon.svg' | rcImage"/>-->
<!--    </div>-->
    <div class="general-error-message-wrapper">
      <div class="main-message">{{'appInitError.oops' | rcTranslate}}</div>
      <div class="main-message">{{message | rcTranslate}}</div>
<!--      <div class="sub-message">{{"comingSoon.subMessage" | rcTranslate}}</div>-->
    </div>
    <button *ngIf="showHomeButton" class="btn btn-primary" (click)="onGoHome()"> {{"comingSoon.linkToHome" | rcTranslate}} </button>
    <button *ngIf="showHomeButton" class="btn btn-primary" (click)="reload()"> {{"genericErrorPopup.reload" | rcTranslate}} </button>
  </div>
</div>
