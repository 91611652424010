import { Injector } from '@angular/core';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { AuthService } from '../core/auth.service';
import { ApiService } from '../core/api.service';
import { RcTranslateService } from '@realcommerce/rc-packages';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie";
import * as i2 from "@realcommerce/rc-packages";
var AmdocsAppInitService = /** @class */ (function () {
    function AmdocsAppInitService(cookieService, _injector, translateService) {
        this.cookieService = cookieService;
        this._injector = _injector;
        this.translateService = translateService;
        this.appFinishedLoaded = false;
    }
    Object.defineProperty(AmdocsAppInitService.prototype, "router", {
        get: function () { return this._injector.get(Router); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AmdocsAppInitService.prototype, "authService", {
        get: function () { return this._injector.get(AuthService); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AmdocsAppInitService.prototype, "apiService", {
        get: function () { return this._injector.get(ApiService); },
        enumerable: true,
        configurable: true
    });
    AmdocsAppInitService.prototype.initApp = function (initQuery, setFixedHeightInMobile, redirectHomePostLogin) {
        var _this = this;
        if (initQuery === void 0) { initQuery = null; }
        if (setFixedHeightInMobile === void 0) { setFixedHeightInMobile = true; }
        if (redirectHomePostLogin === void 0) { redirectHomePostLogin = false; }
        return this.initAppFlow(initQuery).pipe(finalize(function () {
            if (redirectHomePostLogin && _this.isLoginCallback()) {
                _this.router.navigate(['']);
            }
        }));
    };
    AmdocsAppInitService.prototype.loadTranslation = function () {
        var _this = this;
        return this.apiService.getTranslation()
            .pipe(map(function (response) {
            _this.translateService.setTranslation(response);
            return of({});
        }));
    };
    AmdocsAppInitService.prototype.initAppFlow = function (initQuery) {
        var _this = this;
        if (initQuery === void 0) { initQuery = null; }
        if (this.isLoginCallback() && this.authService.isPkceValid()) {
            this.authService.removePkceExp();
            return this.authService.login(this.getLoginCallbackCode()).pipe(mergeMap(function () {
                return _this.initPostLogin(initQuery, true);
            }), catchError(function (error) {
                _this.appInitError = error;
                return of(new AmdocsAppInitResponse(false, error));
            }));
        }
        else if (this.authService.isLoggedIn()) {
            return this.initPostLogin(initQuery);
        }
        else {
            if (this.authService.hasRefreshTokenCookies()) {
                return this.authService.callRefreshUserToken().pipe(mergeMap(function () {
                    return _this.initPostLogin(initQuery);
                }), catchError(function () {
                    return _this.initGetLoginUrlFlow();
                }));
            }
            else {
                return this.initGetLoginUrlFlow();
            }
        }
    };
    AmdocsAppInitService.prototype.initGetLoginUrlFlow = function () {
        var _this = this;
        return this.authService.initGetLoginUrlFlow().pipe(catchError(function (error) {
            _this.appInitError = error;
            return of(new AmdocsAppInitResponse(false, error));
        }));
    };
    // is route a callback from login
    AmdocsAppInitService.prototype.isLoginCallback = function () {
        return window.location.href.indexOf('_callback') > -1;
    };
    // get login callback code
    AmdocsAppInitService.prototype.getLoginCallbackCode = function () {
        return new URLSearchParams(window.location.search).get('code');
    };
    AmdocsAppInitService.prototype.initPostLogin = function (initQuery, isPostLogin) {
        if (initQuery === void 0) { initQuery = null; }
        if (isPostLogin === void 0) { isPostLogin = false; }
        // use token expiration is less the 40 minutes
        if (this.authService.idTokenIsAboutToExpired()) {
            // make a call to refresh token and start the interval
            this.authService.refreshUserToken();
        }
        else {
            // only start the interval
            this.authService.setRefreshTokenInterval();
        }
        return of(new AmdocsAppInitResponse(true, null, isPostLogin));
    };
    AmdocsAppInitService.ngInjectableDef = i0.defineInjectable({ factory: function AmdocsAppInitService_Factory() { return new AmdocsAppInitService(i0.inject(i1.CookieService), i0.inject(i0.INJECTOR), i0.inject(i2.RcTranslateService)); }, token: AmdocsAppInitService, providedIn: "root" });
    return AmdocsAppInitService;
}());
export { AmdocsAppInitService };
var AmdocsAppInitResponse = /** @class */ (function () {
    function AmdocsAppInitResponse(success, error, isPostLogin) {
        if (error === void 0) { error = null; }
        if (isPostLogin === void 0) { isPostLogin = false; }
        this.success = success;
        this.error = error;
        this.isPostLogin = isPostLogin;
    }
    return AmdocsAppInitResponse;
}());
export { AmdocsAppInitResponse };
