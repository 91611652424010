import * as moment_ from 'moment';
import * as i0 from "@angular/core";
var AmdocsTimeService = /** @class */ (function () {
    function AmdocsTimeService() {
        this.momentRef = moment_;
        this.defaultViewDateFormat = 'DD/MM/YYYY';
        this.defaultViewTimeFormat = 'HH:mm';
        this.defaultViewDateTimeFormat = this.defaultViewDateFormat + " " + this.defaultViewTimeFormat;
        this.defaultServerDateFormat = 'YYYY-MM-DD';
        this.defaultServerDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
        this.fullDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
    }
    /**
     * get moment object
     * @param dateString - string of date e.g. '2011-02-15'
     * @param dateFormat - format of date e.g. 'YYYY-MM-DD'
     */
    AmdocsTimeService.prototype.moment = function (dateString, dateFormat) {
        if (dateString === void 0) { dateString = null; }
        if (dateFormat === void 0) { dateFormat = null; }
        if (dateString === null) {
            return this.momentRef();
        }
        else {
            return this.momentRef(dateString, dateFormat);
        }
    };
    /**
     * get moment object from UTC datetime string
     * @param dateString - string of date e.g. '2011-02-15 12:00:00'
     */
    AmdocsTimeService.prototype.momentFromUTCToLocal = function (dateString) {
        return this.momentRef.utc(dateString).local();
    };
    /**
     * Format a date
     * @param date
     * @param from
     * @param to
     * @returns string
     */
    AmdocsTimeService.prototype.format = function (date, from, to) {
        if (from === void 0) { from = this.defaultViewDateFormat; }
        if (to === void 0) { to = this.defaultServerDateFormat; }
        if (this.momentRef(date, from).isValid()) {
            return this.momentRef(date, from).format(to);
        }
        else {
            return null;
        }
    };
    AmdocsTimeService.ngInjectableDef = i0.defineInjectable({ factory: function AmdocsTimeService_Factory() { return new AmdocsTimeService(); }, token: AmdocsTimeService, providedIn: "root" });
    return AmdocsTimeService;
}());
export { AmdocsTimeService };
