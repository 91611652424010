import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie';
import {LanguageKey} from '../models';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private cookieKey = 'language';
  private defaultLanguage: LanguageKey;

  constructor(private cookieService: CookieService) {
    this.defaultLanguage = LanguageKey.english;
  }

  /**
   * get current language
   * @return string
   */
  getLanguage(): string {
    return this.cookieService.get(this.cookieKey) || this.defaultLanguage;
  }

  /**
   * Set language
   * @param lang
   */
  setLanguage(lang: LanguageKey): void {
    this.cookieService.put(this.cookieKey, lang);
  }
}
