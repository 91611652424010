import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { CookieService } from "ngx-cookie";
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie";
import * as i2 from "./api.service";
var AppService = /** @class */ (function () {
    function AppService(cookieService, apiService) {
        this.cookieService = cookieService;
        this.apiService = apiService;
        this.notificationCount = 0;
    }
    AppService.prototype.loadCurrencies = function () {
        return this.apiService.getAllCurrencies()
            .pipe(map(function (response) {
            if (response && response.CURRENCIES) {
                return response.CURRENCIES;
            }
            return [];
        }));
    };
    AppService.ngInjectableDef = i0.defineInjectable({ factory: function AppService_Factory() { return new AppService(i0.inject(i1.CookieService), i0.inject(i2.ApiService)); }, token: AppService, providedIn: "root" });
    return AppService;
}());
export { AppService };
var AmdocsAppInitResponse = /** @class */ (function () {
    function AmdocsAppInitResponse(success, error, isPostLogin) {
        if (error === void 0) { error = null; }
        if (isPostLogin === void 0) { isPostLogin = false; }
        this.success = success;
        this.error = error;
        this.isPostLogin = isPostLogin;
    }
    return AmdocsAppInitResponse;
}());
export { AmdocsAppInitResponse };
