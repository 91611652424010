import * as tslib_1 from "tslib";
import { RcApiService, RcEventBusService } from '@realcommerce/rc-packages';
import { HttpClient } from '@angular/common/http';
import { from, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LanguageService } from './language.service';
import { CookieService } from 'ngx-cookie';
import { FunctionsService } from './functions.service';
import { CONSTANTS } from '../constants';
import { AmdocsGatewayClientService } from '../amdocs-gateway-client/amdocs-gateway-client.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./language.service";
import * as i3 from "@realcommerce/rc-packages";
import * as i4 from "ngx-cookie";
import * as i5 from "./functions.service";
import * as i6 from "../amdocs-gateway-client/amdocs-gateway-client.service";
var ApiService = /** @class */ (function (_super) {
    tslib_1.__extends(ApiService, _super);
    function ApiService(http, languageService, eventBus, cookieService, functionsService, gatewayAPI) {
        var _this = _super.call(this, http) || this;
        _this.http = http;
        _this.languageService = languageService;
        _this.eventBus = eventBus;
        _this.cookieService = cookieService;
        _this.functionsService = functionsService;
        _this.gatewayAPI = gatewayAPI;
        return _this;
    }
    // API CALLS
    ApiService.prototype.getTranslation = function () {
        var fileName = this.languageService.getLanguage();
        return this.http.get("" + environment.translationPath + fileName + ".json");
    };
    ApiService.prototype.uploadReceipt = function (data) {
        var _this = this;
        console.log('data', data);
        var body = {
            IdToken: this.getIdToken(),
            Key: data.KEY,
            TransactionId: this.functionsService.createGuid(),
            Data: {
                TRAVEL: {
                    TRAVEL_ID: data.TRAVEL_ID,
                    START_DATE: data.START_DATE,
                    END_DATE: data.END_DATE,
                    COUNTRY_CODE: data.COUNTRY,
                    EXPENSE_CODE: data.EXPENSE,
                    CURRENCY_CODE: data.CURRENCY,
                    FILE: data.UPLOAD_FILE.fileToUploadEncoded
                }
            }
        };
        var apiClient = this.getApiClient();
        if (data.FROM_EMAIL) {
            return from(apiClient.myreceiptsSaveAttachmentPut({ 'Content-Type': 'application/json' }, body)).pipe(map(function (response) {
                if (response.status === 200) {
                    return response.data;
                }
                return _this.handleError(response);
            }), catchError(function (err) {
                return _this.handleError(err);
            }));
        }
        else {
            return from(apiClient.myreceiptsSaveFilePut({ 'Content-Type': 'application/json' }, body)).pipe(map(function (response) {
                if (response.status === 200) {
                    return response.data;
                }
                return _this.handleError(response);
            }), catchError(function (err) {
                return _this.handleError(err);
            }));
        }
    };
    ApiService.prototype.getTravels = function () {
        var _this = this;
        var body = this.buildPayload();
        var apiClient = this.getApiClient();
        return from(apiClient.myreceiptsQueryRequestlistPost(null, body)).pipe(map(function (response) {
            if (response.status === 200) {
                return response.data;
            }
            return _this.handleError(response);
        }), catchError(function (err) {
            return _this.handleError(err);
        }));
    };
    ApiService.prototype.getAttachmentList = function () {
        var _this = this;
        var body = this.buildPayload();
        var apiClient = this.getApiClient();
        return from(apiClient.myreceiptsAttachmentObjectsPost(null, body)).pipe(map(function (response) {
            if (response.status === 200) {
                return response.data;
            }
            else {
                return _this.handleError(response);
            }
        }), catchError(function (err) {
            return _this.handleError(err);
        }));
    };
    ApiService.prototype.getAttachment = function (key) {
        var _this = this;
        var body = {
            IdToken: this.getIdToken(),
            Key: key
        };
        var apiClient = this.getApiClient();
        return from(apiClient.getAttachmentPost(null, body)).pipe(map(function (response) {
            if (response.status === 200) {
                return response.data;
            }
            return _this.handleError(response);
        }), catchError(function (err) {
            return _this.handleError(err);
        }));
    };
    ApiService.prototype.getGuideData = function () {
        return this.apiGet('/assets/guide/guideData.json');
    };
    ApiService.prototype.getNotificationsList = function (handleError) {
        var _this = this;
        if (handleError === void 0) { handleError = true; }
        var body = this.buildPayload();
        var apiClient = this.getApiClient();
        return from(apiClient.myreceiptsGetEmpNotifPost(null, body)).pipe(map(function (response) {
            if (response.status === 200) {
                if (response.data.Employee_Notification && !Array.isArray(response.data.Employee_Notification)) {
                    response.data.Employee_Notification = [response.data.Employee_Notification];
                }
                return response.data;
            }
            return _this.handleError(response, handleError);
        }), catchError(function (err) {
            return _this.handleError(err, handleError);
        }));
    };
    ApiService.prototype.getLoginUrl = function () {
        return this.gatewayAPI.getLoginApiGatewayClient().call('/oidc/login', 'get').pipe(mergeMap(function (response) {
            return of(response);
        }));
    };
    ApiService.prototype.login = function (code) {
        var params = {
            code: code
        };
        return this.gatewayAPI.getLoginApiGatewayClient().call('/oidc/token', 'get', params).pipe(mergeMap(function (response) {
            return of(response);
        }));
    };
    ApiService.prototype.getLogoutUrl = function () {
        return this.gatewayAPI.getLoginApiGatewayClient().call('/oidc/logout', 'post').pipe(mergeMap(function (response) {
            return of(response);
        }));
    };
    ApiService.prototype.refreshToken = function () {
        return this.gatewayAPI.getLoginApiGatewayClient().call('/oidc/refresh', 'get').pipe(mergeMap(function (response) {
            return of(response);
        }));
    };
    // END OF PUBLIC API CALLS
    /**
     * return api base url
     */
    ApiService.prototype.getBaseUrl = function () {
        return environment.apiBaseUrl;
    };
    /**
     * Run a GET api call
     * @param path
     * @param requestParams
     * @param force
     * @param handleError
     * @return Observable<any>
     */
    ApiService.prototype.apiGet = function (path, requestParams, force, handleError) {
        var _this = this;
        if (requestParams === void 0) { requestParams = {}; }
        if (force === void 0) { force = true; }
        if (handleError === void 0) { handleError = true; }
        return this.get(path, requestParams, force).pipe(mergeMap(function (result) {
            return of(result);
        }), catchError(function (error) {
            return _this.handleError(error, handleError);
        }));
    };
    /**
     * Run a POST api call
     * @param actionName
     * @param requestParams
     * @param handleError
     * @return Observable<any>
     */
    ApiService.prototype.apiPost = function (actionName, requestParams, handleError) {
        var _this = this;
        if (requestParams === void 0) { requestParams = {}; }
        if (handleError === void 0) { handleError = true; }
        return this.post(actionName, requestParams).pipe(switchMap(function (result) {
            if (result.isSucceeded === true) {
                return of(result.content);
            }
            else {
                return throwError(result);
            }
        }), catchError(function (error) {
            return _this.handleError(error, handleError);
        }));
    };
    /**
     * Run a PUT api call
     * @param actionName
     * @param requestParams
     * @param handleError
     * @return Observable<any>
     */
    ApiService.prototype.apiPut = function (actionName, requestParams, handleError) {
        var _this = this;
        if (requestParams === void 0) { requestParams = {}; }
        if (handleError === void 0) { handleError = true; }
        return this.put(actionName, requestParams).pipe(mergeMap(function (result) {
            if (result.isSucceeded === true) {
                return of(result.content);
            }
            else {
                return throwError(result);
            }
        }), catchError(function (error) {
            return _this.handleError(error, handleError);
        }));
    };
    /**
     * Run a DELETE api call
     * @param actionName
     * @param requestParams
     * @param handleError
     * @return Observable<any>
     */
    ApiService.prototype.apiDelete = function (actionName, requestParams, handleError) {
        var _this = this;
        if (requestParams === void 0) { requestParams = {}; }
        if (handleError === void 0) { handleError = true; }
        return this.delete(actionName, requestParams).pipe(mergeMap(function (result) {
            if (result.isSucceeded === true) {
                return of(result.content);
            }
            else {
                return throwError(result);
            }
        }), catchError(function (error) {
            return _this.handleError(error, handleError);
        }));
    };
    ApiService.prototype.setCustomHeaders = function (headers) {
        // todo
    };
    /**
     * Handle API errors
     * @param error
     * @param handleError
     */
    ApiService.prototype.handleError = function (error, handleError) {
        if (handleError === void 0) { handleError = true; }
        if (handleError) {
            if (error && error.status) {
                if (error.status === 401) { // idToken expired
                    this.eventBus.emit(CONSTANTS.EVENTS.SESSION_EXPIRED);
                }
                else if (error.status === 403) {
                    // unhandled error
                    this.eventBus.emit(CONSTANTS.EVENTS.GENERIC_POPUP);
                }
                else { // unhandled error
                    this.eventBus.emit(CONSTANTS.EVENTS.GENERIC_POPUP);
                }
            }
            else {
                // unhandled error
                this.eventBus.emit(CONSTANTS.EVENTS.GENERIC_POPUP);
            }
        }
        return throwError(error);
    };
    ApiService.prototype.returnErrorJson = function (error) {
        var result = {
            isSucceeded: false,
            error: null
        };
        var err = error;
        if (!error || !error.errors) {
            err = {
                code: 0,
                message: ''
            };
        }
        else {
            err = error.errors;
        }
        result.error = err;
        return result;
    };
    ApiService.prototype.buildPayload = function (obj) {
        if (obj === void 0) { obj = null; }
        var body = {
            TransactionId: this.functionsService.createGuid(),
            IdToken: this.getIdToken()
        };
        if (obj) {
            body = tslib_1.__assign({}, obj);
        }
        return body;
    };
    ApiService.prototype.getApiClient = function () {
        var region = 'eu-west-1';
        var credentials = {
            accessKey: this.cookieService.get('AccessKeyId'),
            secretKey: this.cookieService.get('SecretAccessKey'),
            sessionToken: this.cookieService.get('SessionToken'),
            region: region
        };
        return apigClientFactory.newClient(credentials);
    };
    ApiService.prototype.getAgentType = function () {
        return "Mobile";
    };
    ApiService.prototype.getIdToken = function () {
        return this.cookieService.get('open_id_token');
    };
    ApiService.prototype.getScreenId = function () {
        return 'D';
    };
    ApiService.prototype.getAllCurrencies = function () {
        return this.http.get("/assets/currencies/currencies.json");
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.HttpClient), i0.inject(i2.LanguageService), i0.inject(i3.RcEventBusService), i0.inject(i4.CookieService), i0.inject(i5.FunctionsService), i0.inject(i6.AmdocsGatewayClientService)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}(RcApiService));
export { ApiService };
