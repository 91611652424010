export interface Travel {
  TRAVEL_ID: any;
  START_DATE: string;
  END_DATE: string;
  FORMAT_DATE: string;
  COUNTRIES: Country[];
  EXPENSES: Expense[];
  IMAGE_ID?: string;
  CITIES_NAMES?: string;
}

export interface Country {
  COUNTRY_CODE: string;
  COUNTRY_NAME: string;
  COUNTRY_TYPE: string;
  CITIES?: string[];
  CURRENCY: string;
}

export interface Expense {
  EXPENSE_CODE: string;
  EXPENSE_NAME: string;
}

export interface Currency {
  CURRENCY_CODE: string;
  CURRENCY_NAME: string;
}

export enum homeView {
  cardsView = 1,
  uploadMenu = 2,
  uploadFromEmail = 3,
  uploadFromEmailPreview = 4
}
