import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CookieService} from "ngx-cookie";
import {ApiError} from '../models/api.model';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public notificationCount: number = 0;
  public appInitError: ApiError;


  constructor(private cookieService: CookieService, private apiService: ApiService) {
  }


  public loadCurrencies(): Observable<any> {
    return this.apiService.getAllCurrencies()
      .pipe(
        map((response: any) => {
          if (response && response.CURRENCIES) {
            return response.CURRENCIES;
          }
          return [];
        }));
  }


}


export class AmdocsAppInitResponse {
  constructor(
    public success: boolean,
    public error: ApiError = null,
    public isPostLogin: boolean = false
  ) {}
}
