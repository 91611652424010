/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./warning-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@realcommerce/rc-packages";
import * as i3 from "@angular/common";
import * as i4 from "./warning-popup.component";
var styles_WarningPopupComponent = [i0.styles];
var RenderType_WarningPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WarningPopupComponent, data: {} });
export { RenderType_WarningPopupComponent as RenderType_WarningPopupComponent };
function View_WarningPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary popup-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onApprove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "generalPopup.approve")); _ck(_v, 1, 0, currVal_0); }); }
function View_WarningPopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary popup-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "sessionExpiredPopup.reload")); _ck(_v, 1, 0, currVal_0); }); }
export function View_WarningPopupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.ɵba, [i2.RcTranslateService]), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "warning-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "inner-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WarningPopupComponent_1)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WarningPopupComponent_2)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.showApproveBtn; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.showReloadBtn; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), _co.title)); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.text; _ck(_v, 6, 0, currVal_1); }); }
export function View_WarningPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-warning-popup", [], null, null, null, View_WarningPopupComponent_0, RenderType_WarningPopupComponent)), i1.ɵdid(1, 114688, null, 0, i4.WarningPopupComponent, [i2.RcEventBusService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WarningPopupComponentNgFactory = i1.ɵccf("app-warning-popup", i4.WarningPopupComponent, View_WarningPopupComponent_Host_0, { title: "title", text: "text", showApproveBtn: "showApproveBtn", showReloadBtn: "showReloadBtn" }, { submit: "submit" }, []);
export { WarningPopupComponentNgFactory as WarningPopupComponentNgFactory };
