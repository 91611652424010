<div id="nav-main-wrapper" [ngClass]="{'nav-open': open}" (click)="onNavMainWrapperClick($event)">
  <a *ngIf="!open" (click)="onToggle($event)" class="nav-icon icon-menu" ></a>
  <div class="nav-wrapper" [ngClass]="{'open': open}" (click)="onNavWrapperClick($event)">
    <ng-container *ngIf="open">
      <div class="nav-content-wrapper">
        <a class="close-icon icon-close-purple" (click)="onToggle()"> </a>
        <ul class="nav-list-wrapper">
          <li *ngFor="let line of navItems">
            <a class="nav-line-wrapper"
               (click)="onToggle($event,line.displayName)"
               routerLink="{{line.path}}"
               [routerLinkActive]="'active'"
               [routerLinkActiveOptions]="{exact: line.exact}">
              <span class="icon {{line.icon}}"></span>
              <span>{{line.displayName}}</span>
              <img *ngIf="line.showOval" class="oval" [src]="'/assets/icons/oval-red.svg' | rcImage" />
            </a>
          </li>
        </ul>
        <div class="footer-area-wrapper">
          <div class="nav-line-wrapper">
            <span class="icon icon-logout"></span>
            <span (click)="onLogout()">{{"navbar.logout" | rcTranslate}}</span>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="powered-by">
      <img src="/assets/icons/powered-by.png">
    </div>
  </div>
</div>

