import {Injectable, Injector} from '@angular/core';
import {catchError, finalize, map, mergeMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie';
import {AuthService} from '../core/auth.service';
import {ApiError} from '../models/api.model';
import {ApiService} from '../core/api.service';
import {RcTranslateService} from '@realcommerce/rc-packages';


@Injectable({
  providedIn: 'root'
})
export class AmdocsAppInitService {

  public appInitError: ApiError;
  public appFinishedLoaded: boolean = false;

  constructor(
    private cookieService: CookieService,
    private _injector: Injector, private translateService: RcTranslateService) {
  }


  private get router() { return this._injector.get(Router); }

  private get authService() { return this._injector.get(AuthService); }
  private get apiService() { return this._injector.get(ApiService); }

  public initApp(initQuery: string = null, setFixedHeightInMobile = true, redirectHomePostLogin = false): Observable<any> {

    return this.initAppFlow(initQuery).pipe(
      finalize(() => {
        if (redirectHomePostLogin && this.isLoginCallback()) {
          this.router.navigate(['']);
        }
      })
    );
  }

  public loadTranslation(): Observable<any> {
    return this.apiService.getTranslation()
      .pipe(
        map((response: any) => {
          this.translateService.setTranslation(response);
          return of({});
        }));
  }

  private initAppFlow(initQuery: string = null): Observable<AmdocsAppInitResponse> {
    if (this.isLoginCallback() && this.authService.isPkceValid()) {
      this.authService.removePkceExp();
      return this.authService.login(this.getLoginCallbackCode()).pipe(
        mergeMap(() => {
          return this.initPostLogin(initQuery, true);
        }),
        catchError((error: ApiError) => {
          this.appInitError = error;
          return of(new AmdocsAppInitResponse(false, error));
        })
      );
    } else if (this.authService.isLoggedIn()) {
      return this.initPostLogin(initQuery);
    } else {
      if (this.authService.hasRefreshTokenCookies()) {
        return this.authService.callRefreshUserToken().pipe(
          mergeMap(() => {
            return this.initPostLogin(initQuery);
          }),
          catchError(() => {
            return this.initGetLoginUrlFlow();
          })
        );
      } else {
        return this.initGetLoginUrlFlow();
      }
    }
  }

  private initGetLoginUrlFlow(): Observable<AmdocsAppInitResponse> {
    return this.authService.initGetLoginUrlFlow().pipe(
      catchError((error: ApiError) => {
        this.appInitError = error;
        return of(new AmdocsAppInitResponse(false, error));
      })
    );
  }


  // is route a callback from login
  private isLoginCallback(): boolean {
    return window.location.href.indexOf('_callback') > -1;
  }

  // get login callback code
  private getLoginCallbackCode(): string {
    return new URLSearchParams(window.location.search).get('code');
  }

  private initPostLogin(initQuery: string = null, isPostLogin: boolean = false): Observable<AmdocsAppInitResponse> {
    // use token expiration is less the 40 minutes
    if (this.authService.idTokenIsAboutToExpired()) {
      // make a call to refresh token and start the interval
      this.authService.refreshUserToken();
    } else {
      // only start the interval
      this.authService.setRefreshTokenInterval();
    }

    return of(new AmdocsAppInitResponse(true, null, isPostLogin));
  }
}

export class AmdocsAppInitResponse {
  constructor(
    public success: boolean,
    public error: ApiError = null,
    public isPostLogin: boolean = false
  ) {}
}
