import { ApiService } from './api.service';
import { RcEventBusService } from '@realcommerce/rc-packages';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { FunctionsService } from './functions.service';
import { map, mergeMap } from "rxjs/operators";
import { isNullOrUndefined } from "util";
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "@realcommerce/rc-packages";
import * as i3 from "./functions.service";
var CommunicationService = /** @class */ (function () {
    // private communicationMode = 2; // socket
    function CommunicationService(restApi, eventBus, functionsService) {
        this.restApi = restApi;
        this.eventBus = eventBus;
        this.functionsService = functionsService;
        this.modeAPI = 1;
        this.modeSocket = 2;
        this.communicationMode = this.modeAPI; // API
        this.subjects = {};
        // Storing Previous selected travel locaion
        this.selectedTravel = new BehaviorSubject(null);
        this.selectedTravel$ = this.selectedTravel.asObservable();
    }
    CommunicationService.prototype.uploadReceipt = function (data) {
        if (this.communicationMode === this.modeAPI) {
            return this.restApi.uploadReceipt(data);
        }
        else {
            return this.makeSocketCall({});
        }
    };
    CommunicationService.prototype.getTravels = function (force) {
        var _this = this;
        if (force === void 0) { force = true; }
        if (!force && this.travelsCache) {
            return of(this.travelsCache);
        }
        this.travelsCache = null;
        if (this.communicationMode === this.modeAPI) {
            return this.restApi.getTravels().pipe(map(function (response) {
                // ORDER BY
                if (response.Data.length) {
                    response.Data.forEach(function (travel) {
                        if (isNullOrUndefined(travel.TRAVEL.EXPENSES)) {
                            travel.TRAVEL.EXPENSES = [];
                        }
                        if (travel.TRAVEL.EXPENSES.length) {
                            _this.functionsService.sortArrayByKey(travel.TRAVEL.EXPENSES, 'ORDER', false);
                        }
                    });
                }
                _this.travelsCache = response;
                return response;
            }));
        }
        else {
            return this.makeSocketCall({});
        }
    };
    CommunicationService.prototype.getAttachmentList = function () {
        if (this.communicationMode === this.modeAPI) {
            return this.restApi.getAttachmentList().pipe(map(function (response) {
                return response;
            }));
        }
        else {
            return this.makeSocketCall({});
        }
    };
    CommunicationService.prototype.getFileDetails = function (keysArray) {
        var _this = this;
        var calls = [];
        keysArray.forEach(function (key) {
            calls.push(_this.getAttachment(key));
        });
        return forkJoin(calls).pipe(mergeMap(function (response) {
            var formattedRes = [];
            response.forEach(function (res) {
                formattedRes.push(res.Data.Travel);
            });
            return of(formattedRes);
        }));
    };
    CommunicationService.prototype.getAttachment = function (key) {
        if (this.communicationMode === this.modeAPI) {
            return this.restApi.getAttachment(key).pipe(map(function (response) {
                return response;
            }));
        }
        else {
            return this.makeSocketCall({});
        }
    };
    CommunicationService.prototype.makeSocketCall = function (params) {
        var _this = this;
        var transactionId = this.functionsService.createGuid();
        if (this.subjects[transactionId]) {
            this.subjects[transactionId] = new Subject();
        }
        // TODO :: send message via socket
        this.eventBus.on(transactionId, function (payload) {
            if (_this.subjects[transactionId]) {
                if (payload.error) {
                    _this.subjects[transactionId].error(payload);
                }
                else {
                    _this.subjects[transactionId].next(payload);
                }
                _this.subjects[transactionId].complete();
                _this.eventBus.off(transactionId);
                _this.subjects[transactionId].unsubscribe();
                delete _this.subjects[transactionId];
            }
        });
        return this.subjects[transactionId];
    };
    // Set selected travel location 
    CommunicationService.prototype.setTravel = function (travel) {
        this.selectedTravel.next(travel);
    };
    CommunicationService.ngInjectableDef = i0.defineInjectable({ factory: function CommunicationService_Factory() { return new CommunicationService(i0.inject(i1.ApiService), i0.inject(i2.RcEventBusService), i0.inject(i3.FunctionsService)); }, token: CommunicationService, providedIn: "root" });
    return CommunicationService;
}());
export { CommunicationService };
