import { from, of, throwError } from 'rxjs';
import { AMDOCS_CONSTANTS } from '../amdocs-constants';
import { catchError, mergeMap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie";
var AmdocsGatewayClientService = /** @class */ (function () {
    function AmdocsGatewayClientService(loginGatewayConfig, cookieService) {
        this.cookieService = cookieService;
        this.loginGatewayConfig = loginGatewayConfig;
    }
    AmdocsGatewayClientService.prototype.getLoginApiGatewayClient = function (useAws4AuthType) {
        if (useAws4AuthType === void 0) { useAws4AuthType = false; }
        return this.getApiGwClient(this.loginGatewayConfig, useAws4AuthType);
    };
    AmdocsGatewayClientService.prototype.getApiGwClient = function (gwConfig, useAws4AuthType, withCredentials) {
        var _this = this;
        if (useAws4AuthType === void 0) { useAws4AuthType = false; }
        if (withCredentials === void 0) { withCredentials = true; }
        var config = {
            invokeUrl: gwConfig.url,
            region: gwConfig.region,
            accessKey: '',
            secretKey: '',
            sessionToken: '',
            apiKey: '',
            defaultContentType: 'application/json',
            defaultAcceptType: 'application/json',
            appid: gwConfig.appid
        };
        if (withCredentials) {
            config.accessKey = decodeURIComponent(this.cookieService.get(AMDOCS_CONSTANTS.ACCESS_KEY_ID));
            config.secretKey = decodeURIComponent(this.cookieService.get(AMDOCS_CONSTANTS.SECRET_ACCESS_KEY));
            config.sessionToken = decodeURIComponent(this.cookieService.get(AMDOCS_CONSTANTS.SESSION_TOKEN));
        }
        var apigClient = {};
        var invokeUrl = config.invokeUrl;
        var part = /(^https?:\/\/[^\/]+)/g.exec(invokeUrl);
        var endpoint = part ? part[1] : '';
        var pathComponent = invokeUrl.substring(endpoint.length);
        var sigV4ClientConfig = {
            accessKey: config.accessKey,
            secretKey: config.secretKey,
            sessionToken: config.sessionToken,
            serviceName: 'execute-api',
            region: config.region,
            endpoint: endpoint,
            defaultContentType: config.defaultContentType,
            defaultAcceptType: config.defaultAcceptType
        };
        var authType = 'NONE'; // for login gateway
        if (useAws4AuthType && (sigV4ClientConfig.accessKey !== undefined && sigV4ClientConfig.accessKey !== '' && sigV4ClientConfig.secretKey !== undefined && sigV4ClientConfig.secretKey !== '')) {
            authType = 'AWS_IAM'; // For application gateway
        }
        console.log({ invokeUrl: invokeUrl });
        var simpleHttpClientConfig = {
            endpoint: endpoint,
            defaultContentType: config.defaultContentType,
            defaultAcceptType: config.defaultAcceptType
        };
        var apiGatewayClient = apiGateway.core.apiGatewayClientFactory.newClient(simpleHttpClientConfig, sigV4ClientConfig);
        apigClient.call = function (url, method, params, body, additionalParams) {
            if (params === void 0) { params = {}; }
            if (body === void 0) { body = {}; }
            if (additionalParams === void 0) { additionalParams = {}; }
            if (!params) {
                params = {};
            }
            apiGateway.core.utils.assertParametersDefined(params, [], ['body']);
            params.appid = config.appid;
            var reqHeaders = apiGateway.core.utils.parseParametersToObject(params, []);
            if (_this.cookieService.get(AMDOCS_CONSTANTS.TOKEN_KEY)) {
                reqHeaders['x-id-token'] = _this.cookieService.get(AMDOCS_CONSTANTS.TOKEN_KEY);
            }
            var request = {
                verb: method.toUpperCase(),
                path: pathComponent + uritemplate(url).expand(apiGateway.core.utils.parseParametersToObject(params, Object.keys(params))),
                headers: reqHeaders,
                queryParams: apiGateway.core.utils.parseParametersToObject(params, Object.keys(params)),
                body: body
            };
            return _this.handleGatewayResponse(from(apiGatewayClient.makeRequest(request, authType, additionalParams, config.apiKey)));
        };
        return apigClient;
    };
    AmdocsGatewayClientService.prototype.handleGatewayResponse = function (responseObs) {
        var _this = this;
        return responseObs.pipe(mergeMap(function (response) {
            return of(response.data);
        }), catchError(function (err) {
            var errorMsg = err.toString();
            var code = 0;
            if (err.status !== 200) {
                errorMsg = err.data.message;
                code = err.status;
            }
            console.log('handle-error');
            return _this.handleError(errorMsg, code);
        }));
    };
    AmdocsGatewayClientService.prototype.handleError = function (errorMessage, code) {
        if (code === void 0) { code = 0; }
        var apiError = { code: code, errorMessage: errorMessage };
        return throwError(apiError);
    };
    AmdocsGatewayClientService.ngInjectableDef = i0.defineInjectable({ factory: function AmdocsGatewayClientService_Factory() { return new AmdocsGatewayClientService(i0.inject("ILoginGatewayConfig"), i0.inject(i1.CookieService)); }, token: AmdocsGatewayClientService, providedIn: "root" });
    return AmdocsGatewayClientService;
}());
export { AmdocsGatewayClientService };
