import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../core/auth.service';

import {RcEventBusService, RcTranslateService} from '@realcommerce/rc-packages';
import {CONSTANTS} from '../../constants';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['./app-navbar.component.scss']
})
export class AppNavbarComponent implements OnInit {

  public open = false;
  public navItems = [
    {displayName: this.translateService.getText('pageNames.home'), icon: 'icon-home', path: CONSTANTS.ROUTES.HOME.BASE, exact: true },
    {displayName: this.translateService.getText('pageNames.reportIssue'), icon:'icon-report-travel-issue-icon', path: CONSTANTS.ROUTES.REPORT_ISSUE.BASE, exact:true, showOval: false},
    {displayName: this.translateService.getText('pageNames.guide'), icon: 'icon-home', path: CONSTANTS.ROUTES.GUIDE.BASE, exact: true, showOval: true },
    // {displayName: this.translateService.getText('pageNames.onBoarding'), icon: 'icon-home', path: `${CONSTANTS.ROUTES.GUIDE.BASE}/${CONSTANTS.ROUTES.GUIDE.CHILDREN.ON_BOARDING}`}
    ];

  constructor( private eventBus: RcEventBusService,
               private authService: AuthService,
               private translateService: RcTranslateService) { }

  ngOnInit() {
  }

  onToggle($event = null, optionName:string) {
    if ($event) {
      $event.stopPropagation();
    }
    this.open = !this.open;
    this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_BLUR, this.open);
    if(optionName == this.translateService.getText('pageNames.home')){
      this.onClickHome();
    }
    if(optionName == this.translateService.getText('pageNames.reportIssue')){
      window.location.href=CONSTANTS.ROUTES.REPORT_ISSUE.BASE;
    }
  }

  onLogout() {
    sessionStorage.clear();
    // this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, true);
    this.authService.logout()
      .pipe(
        finalize(() => {
          // this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_FULL_PAGE_LOADER, false);
        })
      ).subscribe();


    // this.onToggle();
    // this.     authService.clearGateWayCredentials();
    // setTimeout(() => {
    //   window.location.href = CONSTANTS.LOGOUT_URL;
    // }, 400);
  }

  onNavWrapperClick($event) {
    if (this.open) {
      $event.stopPropagation();
    }
  }

  onNavMainWrapperClick($event) {
    if (this.open) {
      $event.stopPropagation();
      this.onToggle(null,'');
    }
  }

  onClickHome(){
    this.eventBus.emit(CONSTANTS.EVENTS.REDIRECT_HOME);
  }

}
