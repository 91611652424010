import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {CONSTANTS} from './constants';
import {AppPreloadingStrategy} from './app-preloading-strategy';

const routes = [
  {
    path: CONSTANTS.ROUTES.HOME.BASE,
    pathMatch: 'full',
    loadChildren: './feature/home/home.module#HomeModule',
    data: {
      preload: true
    }
  },
  {
    path: '_callback',
    pathMatch: 'full',
    loadChildren: './feature/home/home.module#HomeModule',
    data: {
      preload: true
    }
  },
  {
    path: CONSTANTS.ROUTES.RECEIPT_DETAILS.BASE,
    pathMatch: 'full',
    loadChildren: './feature/receipt-details/receipt-details.module#ReceiptDetailsModule',
    data: {
      preload: true
    }
  },
  {
    path: CONSTANTS.ROUTES.RECEIPT_RESULT.BASE,
    pathMatch: 'full',
    loadChildren: './feature/receipt-result/receipt-result.module#ReceiptResultModule',
    data: {
      preload: true
    }
  },
  {
    path: CONSTANTS.ROUTES.GUIDE.BASE,
    loadChildren: './feature/guide/guide.module#GuideModule'
  },
  {
    path: CONSTANTS.ROUTES.NOTIFICATIONS.BASE,
    loadChildren: './feature/notifications/notifications.module#NotificationsModule'
  }
];

@NgModule({
  providers: [AppPreloadingStrategy],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes,
        {preloadingStrategy: AppPreloadingStrategy}
    )
  ],
  declarations: []
})
export class AppRoutingModule {
}

