import { Injectable } from '@angular/core';
import {RcFunctionsService, RcTranslateService} from '@realcommerce/rc-packages';
import {CONSTANTS} from '../constants';
import {TimeService} from './time.service';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService extends RcFunctionsService {

  constructor(private timeService: TimeService, private translateService: RcTranslateService) {
    super();
  }
}
