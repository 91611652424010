<div id="main-wrapper" class="full-height">
    <div class="full-height show-splash" *ngIf="!appLoaded || !isMinLoadTimePassed"></div>
    <ng-container *ngIf="appLoaded && isMinLoadTimePassed">
      <ng-container *ngIf="!appError">
        <app-navbar *ngIf="displayMenu"></app-navbar>

<!--        <app-notifications *ngIf="displayNotifications"></app-notifications>-->
        <app-alert-messages></app-alert-messages>
        <lib-rc-popup-wrapper *ngIf="displayWarningPopup" [customClass]="'warning-popup'" (closeCallback)="submitWarningPopup()">
          <app-warning-popup [showApproveBtn]="true" (submit)="submitWarningPopup(true)" [title]="warningPopupTitle" [text]="warningPopupText"></app-warning-popup>
        </lib-rc-popup-wrapper>
        <div class="main-content-wrapper full-height" [ngClass]="{'blur': addBlur}">
          <router-outlet></router-outlet>
        </div>
      </ng-container>
      <ng-container *ngIf="appError">
        <app-general-error [showReloadButton]="showReloadButton"></app-general-error>
      </ng-container>

      <lib-rc-popup-wrapper *ngIf="showSessionExpiredPopup" [canClose]="false" [customClass]="'warning-popup'" (closeCallback)="showSessionExpiredPopup = false; onClosePopup()">
        <app-warning-popup [showReloadBtn]="true" [text]="'sessionExpiredPopup.message' | rcTranslate"></app-warning-popup>
      </lib-rc-popup-wrapper>
      <lib-rc-popup-wrapper *ngIf="showGenericErrorPopup" [canClose]="false" [customClass]="'warning-popup'" (closeCallback)="showGenericErrorPopup = false; onClosePopup()">
        <app-warning-popup [showReloadBtn]="true" [text]="'genericErrorPopup.message' | rcTranslate"></app-warning-popup>
      </lib-rc-popup-wrapper>
      <lib-rc-popup-wrapper *ngIf="showInvalidFilePopup" [canClose]="false" [customClass]="'warning-popup'" (closeCallback)="showInvalidFilePopup = false; onClosePopup()">
        <app-warning-popup (submit)="showInvalidFilePopup = false; onClosePopup()" [showApproveBtn]="true" [text]="'invalidFilePopup.message' | rcTranslate"></app-warning-popup>
      </lib-rc-popup-wrapper>
      <app-pwa-ios-notification></app-pwa-ios-notification>
    </ng-container>
</div>
