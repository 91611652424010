import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CommunicationService } from './communication.service';
import { UploadStatus } from '../models/files';
import * as i0 from "@angular/core";
import * as i1 from "./communication.service";
var ReceiptUploadsService = /** @class */ (function () {
    function ReceiptUploadsService(api) {
        this.api = api;
        this.filesToUpload = [];
    }
    ReceiptUploadsService.prototype.uploadFile = function (data) {
        data.UPLOAD_FILE.status = UploadStatus.inProcess;
        this.filesToUpload.push(data.UPLOAD_FILE);
        return this.api.uploadReceipt(data).pipe(map(function (response) {
            data.UPLOAD_FILE.status = UploadStatus.success;
            return of(response);
        }), catchError(function (error) {
            data.UPLOAD_FILE.status = UploadStatus.failed;
            throw new Error(error);
        }));
    };
    ReceiptUploadsService.ngInjectableDef = i0.defineInjectable({ factory: function ReceiptUploadsService_Factory() { return new ReceiptUploadsService(i0.inject(i1.CommunicationService)); }, token: ReceiptUploadsService, providedIn: "root" });
    return ReceiptUploadsService;
}());
export { ReceiptUploadsService };
