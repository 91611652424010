import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CONSTANTS} from '../../../constants';
import {RcEventBusService} from '@realcommerce/rc-packages';


@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.scss']
})
export class WarningPopupComponent implements OnInit {

  constructor(private eventBus: RcEventBusService) { }

  @Input() title;
  @Input() text = '';
  @Input() showApproveBtn = false;
  @Input() showReloadBtn = false;

  @Output() submit: EventEmitter<any> = new EventEmitter();

  public approved = false;

  ngOnInit() {
    this.eventBus.emit(CONSTANTS.EVENTS.TOGGLE_BLUR, true);
    this.title = this.title || 'generalPopup.warning'; // TODO
  }

  cancel() {
    this.approved = false;
    this.close();
  }

  onApprove() {
    this.approved = true;
    this.close();
  }

  close() {
    this.submit.emit(this.approved);
  }

  reload() {
    window.location.reload();
  }
}
