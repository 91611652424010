import {FormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {PwaService} from './pwa.service';
import {RouterModule} from '@angular/router';
import {CoreModule} from './core/core.module';
import {ShareModule} from './share/share.module';
import {AppNavbarComponent} from './feature/app-navbar/app-navbar.component';
import {AlertMessagesComponent} from './feature/alert-messages/alert-messages.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {IImagesConfig} from '@realcommerce/rc-packages/lib/rc-ui/pipes/rc-image/rc-image.pipe';
import {catchError, mergeMap} from 'rxjs/operators';
import {of, throwError} from 'rxjs';
import {AmdocsAppInitResponse, AmdocsAppInitService} from './amdocs-app-init/amdocs-app-init.service';

const imagesConfig: IImagesConfig = {
  baseUrl: ''
};

export function initializeApp(appInitService: AmdocsAppInitService): any {
  return (): Promise<any> => {
    return appInitService.loadTranslation().pipe(
      mergeMap(() => {
        return appInitService.initApp().pipe(
          mergeMap((appInitResponse: AmdocsAppInitResponse) => {
            if (appInitResponse.success) {
              return of(true); // TODO :: init app
            } else {
              if (!appInitService.appInitError) {
                appInitService.appInitError = appInitResponse.error;
              }
              console.log('APP INIT ERROR: ' + appInitResponse.error);
            }
            return of(appInitResponse);
          }),
          catchError((error) => {
            appInitService.appInitError = error;
            appInitService.appFinishedLoaded = true;
            document.body.className = document.body.className.replace('show-splash', '');
            return throwError(error);
          }),
        );
      })
    ).toPromise();
  };
}


const loginGatewayConfig = {
  url: environment.loginGWBaseURL,
  region: 'eu-west-1',
  appid: 'myreceipts', // environment.appId,
  refreshTokenInterval: environment.refreshTokenInterval
};

@NgModule({
  declarations: [
    AppComponent,
    AppNavbarComponent,
    AlertMessagesComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    CoreModule,
    ShareModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production})
  ],
  providers: [
    {
      provide: 'ILoginGatewayConfig',
      useValue: loginGatewayConfig
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AmdocsAppInitService],
      multi: true
    },
    PwaService,
    {
      provide: 'ImageConfig',
      useValue: imagesConfig
    }],
  bootstrap: [AppComponent]
})

export class AppModule {
}
