// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// import 'mimic';

export const misnpBaseURL = 'https://misnp.amdocs.com';

export const environment = {
  loginGWBaseURL: `${misnpBaseURL}/cas-dev`,
  production: true,
  apiBaseUrl: '',
  domain: 'misnp.amdocs.com',
  translationPath: '/assets/translate/',
  refreshTokenURL: 'https://myreceipts-dev.misnp.amdocs.com/_refreshsession',
  refreshTokenInterval: 2400000,
  sts: {
    baseUrl: 'https://sts.amazonaws.com',
    action: 'AssumeRoleWithWebIdentity',
    roleSessionName: 'myreceipts-dev',
    roleArn: 'arn:aws:iam::220210465306:role/travel_app_user',
    durationSeconds: 3600,
    version: '2011-06-15'
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
