import {Inject, Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie';
import {Router} from '@angular/router';
// import {JwtHelperService} from '@auth0/angular-jwt';
import {AmdocsTimeService} from '../amdocs-time/amdocs-time.service';
import {AMDOCS_CONSTANTS} from '../amdocs-constants';
import {ILoginCredentials, IRedirectResponse} from '../models/auth.model';
import {AmdocsAppInitResponse} from './app.service';
import {ApiError} from '../models/api.model';
import {ILoginGatewayConfig} from '../models/configs.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

  private refreshTokenThread: any;
  private loginConfig: ILoginGatewayConfig;


  constructor(@Inject('ILoginGatewayConfig') loginGatewayConfig: ILoginGatewayConfig,
              private apiService: ApiService, private cookieService: CookieService,
              private router: Router,
              private timeService: AmdocsTimeService) {
    this.loginConfig = loginGatewayConfig;
  }

  // public makeSTSLogin(): Observable<any> {
  //   if (!this.cookieService.get('TOKEN')) {
  //     return throwError({});
  //   }
  //   return this.apiService.makeSTSLogin().pipe(map((response: any) => {
  //     try {
  //       const credentials = response.AssumeRoleWithWebIdentityResponse.AssumeRoleWithWebIdentityResult.Credentials;
  //       this.setGateWayCredentials(credentials);
  //       return response;
  //     } catch (e) {
  //       return throwError(response);
  //     }
  //   }));
  // }

  // public setGateWayCredentials(credentials: any) {
  //   this.cookieService.put('AccessKeyId', credentials.AccessKeyId);
  //   this.cookieService.put('Expiration', credentials.Expiration);
  //   this.cookieService.put('SecretAccessKey', credentials.SecretAccessKey);
  //   this.cookieService.put('SessionToken', credentials.SessionToken);
  // }

  public clearGateWayCredentials() {
    this.cookieService.remove('AccessKeyId');
    this.cookieService.remove('Expiration');
    this.cookieService.remove('SecretAccessKey');
    this.cookieService.remove('SessionToken');
    this.cookieService.remove('TOKEN');
  }

  // public isADFSExpired() {
  //   const token = this.cookieService.get('TOKEN');
  //   return !token || this.jwtHelper.isTokenExpired(token);
  // }

  public isPkceValid(): boolean {
    const now = (this.timeService.moment().unix() * 1000);
    const exp = this.cookieService.get(AMDOCS_CONSTANTS.PKCE_EXPIRATION);
    if (exp) {
      return parseInt(exp, 10) > now;
    }
    return false;
  }

  public removePkceExp(): void {
    this.cookieService.remove(AMDOCS_CONSTANTS.PKCE_EXPIRATION);
  }

  public login(code: string): Observable<ILoginCredentials> {
    return this.apiService.login(code).pipe(
      mergeMap((response: any): Observable<any> => {
        this.setLoginCredentials(response);
        return of(response);
      })
    );
  }

  private setLoginCredentials(loginCredentials: ILoginCredentials): void {
    const options = {
      path: '/',
      secure: true
    };
    const stsCreds = loginCredentials.stsCredentials;
    this.cookieService.put(AMDOCS_CONSTANTS.ACCESS_KEY_ID, stsCreds.accessKeyId, options);
    this.cookieService.put(AMDOCS_CONSTANTS.SECRET_ACCESS_KEY, stsCreds.secretAccessKey, options);
    this.cookieService.put(AMDOCS_CONSTANTS.SESSION_TOKEN, stsCreds.sessionToken, options);
    this.cookieService.put(AMDOCS_CONSTANTS.EXPIRATION, stsCreds.expiration, options);

    this.cookieService.put(AMDOCS_CONSTANTS.TOKEN_KEY, loginCredentials.idToken, options);
    this.cookieService.put(AMDOCS_CONSTANTS.TOKEN_EXPIRATION_KEY, loginCredentials.idTokenExpiration.toString(), options);
    this.cookieService.put(AMDOCS_CONSTANTS.CAS_TOKEN_EXPIRATION_KEY, loginCredentials.casTokenExpiration.toString(), options);
  }

  /**
   * return if user is logged in
   */
  public isLoggedIn(): boolean {
    const token = this.getToken();
    if (token) {
      const exp = this.cookieService.get(AMDOCS_CONSTANTS.TOKEN_EXPIRATION_KEY);
      if (exp) {
        return this.isExpired(parseInt(exp, 10)) ? false : true;
      } else {
        return true;
      }
    }
    return false;
  }

  getToken(): string {
    return this.cookieService.get(AMDOCS_CONSTANTS.TOKEN_KEY);
  }

  public isExpired(exp): boolean {
    const now = (this.timeService.moment().unix() * 1000);
    return now > exp;
  }

  public hasRefreshTokenCookies(): boolean {
    const now = (this.timeService.moment().unix() * 1000);
    const exp = this.cookieService.get(AMDOCS_CONSTANTS.CAS_TOKEN_EXPIRATION_KEY);
    if (exp) {
      return parseInt(exp, 10) > now;
    }
    return false;
  }

  public callRefreshUserToken(): Observable<ILoginCredentials> {
    return this.apiService.refreshToken().pipe(
      mergeMap((response: ILoginCredentials) => {
        this.setLoginCredentials(response);
        return of(response);
      }),
      catchError((error) => {
        this.initGetLoginUrlFlow().subscribe();
        return throwError(error);
      })
    );
  }

  public initGetLoginUrlFlow(): Observable<AmdocsAppInitResponse> {
    sessionStorage.removeItem('orig-path');
    return this.getLoginURL().pipe(
      mergeMap((response: IRedirectResponse) => {
        sessionStorage.setItem('orig-path', window.location.pathname + window.location.search);
        if (response.redirectUri) {
          this.setpkceExp(response.pkceExpiration);
          (window as any).location = response.redirectUri;
          // window.open(response.redirectUri);
          return of(new AmdocsAppInitResponse(true));
        } else {
          return throwError(new ApiError(0, 'could not get login url'));
        }
      })
    );
  }

  public getLoginURL(): Observable<IRedirectResponse> {
    return this.apiService.getLoginUrl().pipe(
      mergeMap((response: IRedirectResponse) => {
        return of(response);
      })
    );
  }

  public setpkceExp(pkceExp: number): void {
    const options = {
      path: '/',
      secure: true
    };
    this.cookieService.put(AMDOCS_CONSTANTS.PKCE_EXPIRATION, `${pkceExp}`, options);
  }

  public idTokenIsAboutToExpired(): boolean {
    const now = (this.timeService.moment().unix() * 1000);
    const exp = this.cookieService.get(AMDOCS_CONSTANTS.TOKEN_EXPIRATION_KEY);
    if (exp) {
      return parseInt(exp, 10) - now < 2400000;
    }
    return true;
  }

  public refreshUserToken(): void {
    this.clearRefreshTokenInterval();
    this.callRefreshUserToken().pipe(
      mergeMap((response: ILoginCredentials) => {
        this.setRefreshTokenInterval();
        return of(response);
      })
    ).subscribe();
  }

  public clearRefreshTokenInterval(): void {
    if (this.refreshTokenThread) {
      clearInterval(this.refreshTokenThread);
    }
  }

  public setRefreshTokenInterval(): void {
    this.clearRefreshTokenInterval();
    this.refreshTokenThread = setInterval(() => {
      this.refreshUserToken();
    }, this.loginConfig.refreshTokenInterval);
  }

  public logout(): Observable<IRedirectResponse> {
    return this.apiService.getLogoutUrl().pipe(
      mergeMap((response: IRedirectResponse) => {
        this.cookieService.removeAll();
        (<any> window).location = response.redirectUri;
        return of(response);
      })
    );
  }
}

