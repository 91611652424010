import {Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';

@Injectable()
export class PwaService {

    public promptEvent: any;

    constructor(private swUpdate: SwUpdate) {
        swUpdate.available.subscribe(event => {
            if (confirm('new version exists - reload ?')) {
                window.location.reload();
            }
        });
        window.addEventListener('beforeinstallprompt', event => {
            console.log('inside event beforeinstallprompt');
            this.promptEvent = event;
        });
        window.addEventListener('appinstalled', (event) => {
            console.log('app installed');
        });
    }

}
